<template>
  <div class="SimpleHero" :class="showOnMobile ? 'showOnMobile' : ''">
    <img class="dummy" :src="require(`@/assets/${dummy}`)" />
    <img
      class="slide"
      :src="src || require(`@/assets/img/_Carlson_Quarry_032.jpg`)"
    />
    <div class="text">
      <h3>{{ title }}</h3>
      <p>
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleHero",
  props: {
    src: String,
    title: String,
    blurb: String,
    showOnMobile: Boolean,
  },
  data() {
    return {
      dummy: "img/_Carlson_Quarry_021.jpg",
    };
  },
};
</script>

<style scoped lang="scss">
.SimpleHero {
  position: relative;
  max-height: 545px;
  margin: 0 auto;
  @include smx {
    min-height: 180px;
    &.showOnMobile p {
      display: block !important;
      top: 0 !important;
      padding-left: 12px !important;
      .BigInput {
        margin-top: -28px;
        // margin-left: 12px;
        margin-bottom: 16px;
      }
    }
  }

  .dummy {
    visibility: hidden;
    width: 100%;
    position: relative;
    z-index: 1;
    @include smx {
      display: none;
    }
    @include med {
      display: none;
    }
  }

  .slide {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-height: 545px;
    object-fit: cover;
    object-position: center center;
    @include smx {
      position: static;
    }
    @include med {
      position: static;
    }

    &.vertical {
      width: 40%;
      left: 30%;
      max-height: 545px;
    }
  }

  .videoslide {
    max-height: 545px;
  }

  .text {
    padding: 80px 0px 192px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background-image: linear-gradient(
      0deg,
      rgba(31, 51, 83, 0.86) 45%,
      rgba(32, 143, 204, 0) 96%
    );

    @include med {
      padding-bottom: 196px !important;
    }
    @include smx {
      padding: 0 !important;
      position: static;
    }

    h3 {
      position: relative;
      top: 100px;
      color: $blue;
      text-transform: uppercase;
      font-size: 28px;
      @extend .limit;
      @include smx {
        position: static;
        font-size: 18px;
        color: white;
        padding-bottom: 20px;
        display: none;
      }
    }

    p {
      position: relative;
      top: 80px;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      @extend .limit;
      padding-right: 35%;
      @include smx {
        padding-right: 0 !important;
        display: none;
      }
    }
  }

  .bar {
    position: absolute;
    z-index: 4;
    bottom: -5px;
    width: 100%;
    background: $navy;
    @include smx {
      position: relative;
      bottom: auto;
    }
    @include med {
      position: relative;
      bottom: auto;
    }

    ul {
      text-align: right;
      li {
        padding: 16px 40px;
        width: auto;
        display: inline-block;
        min-width: 50px;
        height: 80px;
        background-size: cover;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.125em;
        text-align: center;
        border-right: 1px solid $grey;
        transition: all 0.4s;
        font-weight: 400;
        opacity: 0.5;
        cursor: pointer;

        @include smx {
          height: 50px;
          width: calc(100% / 6);
          min-width: 0;
          padding: 0;
        }

        &:last-of-type {
          border-right: 0;
        }

        &:hover {
          transition: all 0.4s;
          background-color: $blue;
          opacity: 1;
        }
      }
    }
  }
}
</style>
