<template>
  <div
    class="Testimonials"
    v-if="fields && (fields.headline != null) & (fields.headline != '')"
  >
    <h2>{{ fields.headline }}</h2>
    <img class="mainImg" :src="fields.image" />
    <p>
      <img class="quoteImg" src="@/assets/img/quote.png" /> {{ fields.quote }}”
    </p>
    <span
      >{{ fields.attribution_name }} <br />
      {{ fields.attribution_title }}
    </span>
    <div style="text-align:center">
      <Button
        inverse
        center
        auto
        :text="fields.button_text"
        :href="fields.button_url"
      ></Button>
    </div>
  </div>
</template>

<script>
import Button from "@/atoms/Button.vue";

export default {
  name: "Testimonials",
  components: { Button },
  props: {
    fields: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.Testimonials {
  background-image: linear-gradient(-160deg, #1f3353 29%, #208fcc 100%);
  padding-top: 108px;
  padding-bottom: 8px;

  h2 {
    max-width: 50%;
    margin: 0 auto;
    color: white;
    text-align: center;
    padding-bottom: 128px;
    font-size: 28px;
    letter-spacing: 0.06125em;
    line-height: 1.25em;
  }

  .mainImg {
    display: block;
    max-width: 40%;
    width: 100%;
    margin: 0 auto 32px;
  }

  .quoteImg {
    width: 55px;
    position: absolute;
    top: -1.25em;
    left: -0.85em;
  }

  p {
    position: relative;
    color: white;
    margin: 128px auto 0;
    max-width: 40%;
    font-weight: bold;
    text-indent: 3em;
    font-size: 20px;
    line-height: 36px;
  }

  span {
    display: block;
    margin: 56px auto 108px;
    max-width: 60%;
    color: white;
    text-align: right;
  }

  .Button {
    margin-bottom: 96px;
  }

  @include smx {
    .Button {
      width: calc(100% - 64px);
      margin-left: 32px;
      margin-right: 32px;
    }
  }
}
</style>
