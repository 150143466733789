<template>
  <li class="MegaNavLink">
    <a v-if="link.href.includes('http')" target="_blank" :href="link.href">
      <img :src="require(`@/assets/img/${link.icon}`)" />
      <span>{{ link.title }}</span>
    </a>
    <router-link v-else :class="{ active: link.active }" :to="link.href">
      <img :src="require(`@/assets/img/${link.icon}`)" />
      <span>{{ link.title }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "MegaNavLink",
  props: {
    link: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.MegaNavLink {
  display: inline-block;
  list-style: none;
  margin-left: 24px;

  a {
    color: white !important;
    font-weight: normal;
    text-decoration: none;
    transition: all 0.4s;
    display: inline-block;
    text-align: center;
    width: 150px;
    vertical-align: top;
    opacity: 1;
    transition: all 0.4s;

    img {
      display: inline-block;
      height: 75%;
      max-height: 50px;
      margin-bottom: 8px;
    }

    span {
      display: block;
    }

    &.active {
      color: $blue;
    }

    &.router-link-exact-active {
      color: $blue;
    }

    &:hover {
      opacity: 0.5;
      transition: all 0.4s;
    }
  }
}
</style>
