<template>
  <span class="InternalLink">
    <router-link class="" :to="href">{{ title }}</router-link>
  </span>
</template>

<script>
export default {
  name: "InternalLink",
  props: {
    href: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.InternalLink {
  display: inline-block;
  list-style: none;

  a {
    color: $blue;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.4s;

    &.active {
      color: $navy;
    }

    &.router-link-exact-active {
      color: $navy;
    }

    &:hover {
      color: $navy;
      transition: all 0.4s;
    }
  }
}
</style>
