<template>
  <div class="Story" v-if="story">
    <div class="text" :class="story.featured_img_url && 'hasImage'">
      <h4>
        <router-link v-html="story.title.rendered" class="readMore" :to="'/news/' + story.slug" />
      </h4>
      <Rule half :color="COLORS.$navy" />
      <p v-html="story.excerpt.rendered"></p>
      <router-link class="readMore" :to="'/news/' + story.slug">Read More</router-link>
    </div>
    <router-link class="readMoreImg" :to="'/news/' + story.slug">
      <img
        class="StoryFeaturedImage"
        v-if="story && story.featured_img_url"
        :src="story.featured_img_url"
        :alt="story.slug"
      />
    </router-link>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";

export default {
  name: "Story",
  components: { Rule },
  props: {
    story: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.Story {
  @extend .cf;
  @include med {
    flex-direction: column-reverse;
    display: flex;
    border-bottom: 1px solid #eee;
    margin-bottom: 64px;
    padding-bottom: 0px;
  }

  .text {
    float: left;
    max-width: 70%;
    @extend .destruct;
    @extend .p0;

    &.hasImage {
      margin-bottom: 32px;
    }
  }

  .image,
  .StoryFeaturedImage {
    max-height: 180px;
    float: right;
    display: block;
    width: auto;
    @extend .destruct;
    padding-bottom: 16px;
    @extend .p0;

    @include med {
      // max-width: 50% !important;
      max-width: 100% !important;
      max-height: none !important;
      width: auto !important;
      float: none !important;
    }
  }

  h4 {
    text-transform: none;
    font-size: 20px;
    color: $navy;
    transition: all 0.4s;
    cursor: pointer;

    &:hover {
      transition: all 0.4s;
      color: $blue;
    }

    @include med {
      font-size: 18px !important;
    }

    a {
      all: unset !important;
      &:visited {
        color: inherit;
      }
    }
  }

  .Rule {
    padding-top: 12px;
  }

  p {
    padding: 16px 0 20px;
    font-size: 16px;
    line-height: 28px;
  }

  .readMore {
    color: $blue;
    display: inline-block;
    margin-bottom: 12px;
    font-style: italic;
    padding: 8px 0 4px;
    @include funLink($blue);

    span {
      display: inline-block;
      @include arrow-right(5px, $blue);
      position: relative;
      top: 0px;
      left: 7px;
    }
  }
}
</style>
