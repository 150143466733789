<template>
  <div
    class="Rule"
    :class="{ center: center, half: half, thirds: thirds, quarter: quarter }"
    :style="'border-bottom-color:' + (color || '#D8D8D8')"
  ></div>
</template>

<script>
export default {
  name: "Rule",
  props: {
    color: {
      type: String,
    },
    half: {
      type: Boolean,
    },
    thirds: {
      type: Boolean,
    },
    quarter: {
      type: Boolean,
    },
    center: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.Rule {
  border-bottom-style: solid;
  border-bottom-width: 1px !important;
  padding-top: 24px;

  &.half {
    width: 50%;
    @include smx {
      width: 100%;
    }
    @include med {
      width: 100%;
    }
  }

  &.thirds {
    width: 66.6666%;
    @include smx {
      width: 100%;
    }
    @include med {
      width: 100%;
    }
  }

  &.quarter {
    width: 25%;
    @include smx {
      width: 100%;
    }
    @include med {
      width: 100%;
    }
  }

  &.right {
    float: right;
  }

  &.center {
    margin: 48px auto;
    display: block;
  }
}
</style>
