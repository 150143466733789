<template>
  <div
    class="LocateDealer"
    :style="'background-image: url(' + require(`@/assets/img/map.png`) + ')'"
  >
    <Button
      :clickFunction="show"
      inverse
      center
      text="Locate a Representative"
      auto
      pb
      v-scroll-reveal="{ opacity: 0 }"
    />
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";
import Button from "@/atoms/Button";

export default {
  name: "LocateDealer",
  components: { Rule, Button },
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    show() {
      this.$modal.show("locateModal");
    },
    hide() {
      this.$modal.hide("locateModal");
    },
  },
};
</script>

<style scoped lang="scss">
.LocateDealer {
  color: inherit;
  height: 450px;
  background-size: 200%;
  background-position: center center;
  background-repeat: no-repeat;

  /deep/ .Button {
    top: 300px;
    left: calc(50% - 136px);
    position: relative;
  }

  @media screen and (max-width: 768px) {
    height: auto !important;
    /deep/ .Button {
      position: static;
      margin-top: 80px;
    }
  }
}
</style>
