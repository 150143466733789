<template>
  <div
    class="Button"
    :class="{
      inverse: inverse,
      half: half,
      quarter: quarter,
      third: third,
      auto: auto,
      thirds: thirds,
      center: center,
      pb: pb,
    }"
  >
    <a v-if="href && newTab" :href="href" target="_blank">{{ text }}</a>
    <a v-else-if="href" :href="href">{{ text }}</a>
    <a v-else-if="clickFunction" @click="clickFunction() || ''">{{ text }}</a>
    <span v-else><slot></slot></span>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
    },
    href: {
      type: String,
    },
    clickFunction: {
      type: Function,
    },
    color: {
      type: String,
    },
    hover: {
      type: String,
    },
    half: {
      type: Boolean,
    },
    newTab: {
      type: Boolean,
    },
    quarter: {
      type: Boolean,
    },
    auto: {
      type: Boolean,
    },
    third: {
      type: Boolean,
    },
    thirds: {
      type: Boolean,
    },
    center: {
      type: Boolean,
    },
    pb: {
      type: Boolean,
    },
    inverse: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.Button {
  display: inline-block;
  background: $blue;
  color: $light;
  border-bottom: 0px solid $blue;
  width: 100%;
  font-weight: 900;
  letter-spacing: 0.125em;
  text-align: center;
  cursor: pointer;
  position: relative;
  bottom: 0px;
  transition: all 0.4s;

  &:hover {
    background-color: $navy;
    transition: all 0.4s;
    a {
      border-bottom: 2px solid $blue;
      padding-bottom: calc(1.25em - 2px);
    }
  }

  &.inverse {
    background: $navy;
    transition: all 0.4s;

    &:hover {
      background: $blue;
      transition: all 0.4s;
      a {
        padding-bottom: calc(1.25em - 2px);
        border-bottom: 2px solid $navy;
      }
    }
  }

  &.half {
    width: 50%;
    @include smx {
      width: 100%;
    }
  }

  &.quarter {
    width: 25%;
    a {
      padding: 1.25em;
    }
    @include smx {
      width: 50%;
    }
  }

  &.third {
    width: 33.33333%;
    @include smx {
      width: 50%;
    }
  }

  &.thirds {
    width: 66.6666%;
    @include smx {
      width: 50%;
    }
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &.auto {
    display: inline-block;
    margin: 0 auto;
    width: auto;
  }

  &.pb {
    margin-bottom: 128px;
    @include smx {
      margin-left: 32px;
      margin-right: 32px;
      width: calc(100% - 64px);
    }
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.75em;
    display: block;
    padding: 1.25em 3em;
    width: 100%;
  }
}
</style>
