<template>
  <div
    v-if="src || this.$slots.default || title.trim() !== ''"
    class="TwoUpStatic"
    :style="'background-color: ' + backgroundColor || 'white'"
  >
    <div>
      <div
        class="text"
        :class="{ full: !src }"
        v-scroll-reveal="{ opacity: 0 }"
      >
        <div class="cms">
          <h2>{{ title }}</h2>
          <slot></slot>
        </div>
      </div>
      <div class="imageBox">
        <img
          v-if="src"
          class="image"
          :src="src"
          v-scroll-reveal="{ opacity: 0 }"
        />
        <div
          v-if="includeButton && buttonLink && buttonText"
          style="text-align: center"
        >
          <Button
            :href="buttonLink"
            :text="buttonText"
            auto
            pb
            v-scroll-reveal="{ opacity: 0 }"
            center
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";
import Button from "@/atoms/Button";

export default {
  name: "TwoUpStatic",
  components: { Rule, Button },
  props: {
    includeButton: {
      type: Boolean,
    },
    buttonText: {
      type: String,
    },
    buttonLink: {
      type: String,
    },
    title: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    blurb: {
      type: String,
    },
    src: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.TwoUpStatic {
  padding-top: 48px;
  padding-bottom: 32px;

  @include smx {
    padding-top: 16px;
    padding-bottom: 0px;
  }

  > div {
    @extend .cf;
    @extend .limit;
    @extend .destruct;
    padding-bottom: 16px;
  }

  .Rule {
    clear: both;
    padding-bottom: 48px;
  }

  .Button {
    margin-bottom: 96px;
    margin: 0 auto;
    @include smx {
      width: 100%;
    }
  }

  .text {
    float: left;
    max-width: 68%;
    &.full {
      max-width: 100%;
    }
    width: 100%;
    @extend .p0;
    @include smx {
      max-width: 100%;
      h2,
      p {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    h2 {
      font-size: 24px;
    }
  }

  .imageBox {
    @extend .p0;
    width: 32%;
    float: right;
    display: block;
    position: relative;
    padding-bottom: 12px;

    img {
      display: block;
      width: 100%;
      max-width: 320px;
      margin: 0 auto 16px;
    }

    @include smx {
      width: 100% !important;
    }
  }

  .Rule {
    padding-top: 12px;
  }

  h2 {
    color: $blue;
    padding-bottom: 8px;
    @extend .destruct;
    padding-right: 25%;
    line-height: 1.55em;
    font-size: 20px;
  }

  h3 {
    color: $navy;
    padding-bottom: 8px;
    @extend .destruct;
    line-height: 1.55em;
    letter-spacing: 0.06125em;
    cursor: pointer;
    @include funLink($blue);
    font-size: 32px;
    display: inline-block;
    &:hover {
      color: $blue;
    }
  }

  .readMore {
    color: $blue;
    display: inline-block;
    margin-bottom: 64px;
    font-style: italic;
    padding: 8px 0 4px;
    @include funLink($blue);

    span {
      display: inline-block;
      @include arrow-right(5px, $blue);
      position: relative;
      top: 0px;
      left: 7px;
    }
  }

  .ProductNew & {
    padding-top: 64px;
  }
}
</style>
