<template>
  <div
    class="TextInput"
    :class="{ half: half, quarter: quarter, third: third, center: center }"
  >
    <input type="text" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    placeholder: {
      type: String,
    },
    color: {
      type: String,
    },
    hover: {
      type: String,
    },
    half: {
      type: Boolean,
    },
    quarter: {
      type: String,
    },
    third: {
      type: String,
    },
    center: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.TextInput {
  display: inline-block;
  padding-top: 24px;
  input {
    @extend .got;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    font-size: 12px;
    padding: 12px 16px;
    width: 100%;
    font-weight: 500;
    letter-spacing: 0.0236125em;
  }

  ::placeholder {
    color: #aaa;
    font-style: italic;
  }

  &.half {
    width: 50%;
  }

  &.quarter {
    width: 25%;
  }

  &.third {
    width: 33.33333%;
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &:active {
    border: 1px solid $navy;
    transition: all 0.4s;
  }
}
</style>
