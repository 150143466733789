export default {
  methods: {
    toTitleCase: function (str) {
      str = str.replace("-", " ");
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },
    toPascalCase: function (str) {
      str = str.replace("-", " ");
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ").replace(" ", "");
    },
  },
  mounted: function () {
    if (typeof window.scrollOnLoad === "undefined") {
      var parser = document.createElement("a");
      parser.href = window.location.href;
      window.scrollOnLoad = parser.hash;
    }
    if (
      window.scrollOnLoad != "" &&
      document.getElementById(window.scrollOnLoad.substring(1)) !== null &&
      !window.location.href.includes("/search")
    ) {
      this.$scrollTo(window.scrollOnLoad);
      window.scrollOnLoad = "";
    }
  },
  data: function () {
    return {
      OPTIONS_ROUTE: window.API + "/acf/v3/options/options",
      COLORS: {
        $blue: "#208FCC",
        $navy: "#1F3353",
        $light: "#ffffff",
        $dark: "#555555",
        $grey: "#979797",
        $gray: "#979797",
        $lightestGrey: "#eee",
        $lightestGray: "#eee",
      },
      TAGS: {
        "accident-investigation": 13,
        construction: 2,
        "data-collection": 8,
        mining: 4,
        gis: 10,
        hardware: 3,
        instruments: 5,
        "land-development": 11,
        landfill: 7,
        "machine-control": 6,
        "office-software": 12,
        survey: 9,
        "waste-management": 16,
        solar: 914,
        "quarry-aggregate": 915,
      },
      TAG_INDEXES: {
        13: "accident-investigation",
        2: "construction",
        8: "data-collection",
        4: "mining",
        10: "gis",
        3: "hardware",
        5: "instruments",
        11: "land-development",
        7: "landfill",
        6: "machine-control",
        12: "office-software",
        "9 ": "survey",
        16: "waste-management",
        914: "solar",
        915: "quarry-aggregate",
        916: "dredging",
        917: "contract-drilling",
      },
      INDUSTRIES: [
        "land-development",
        "mining",
        "solar",
        "quarry-aggregate",
        "waste-management",
      ],
      PRODUCT_FAMILIES: [
        "office-software",
        "instruments",
        "machine-control",
        "data-collection",
      ],
    };
  },
};
