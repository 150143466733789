<template>
  <div class="News" id="news">
    <h2>News and Updates</h2>
    <h4 style="padding-bottom: 40px; text-transform: none">
      Read the latest news from Carlson Software or
      <a
        href="https://visitor.r20.constantcontact.com/d.jsp?llr=vguatwcab&p=oi&m=1102417252565&sit=ygfog98db&f=4e47ecb8-9a7d-44ef-abf8-5f67d8f8f4c3"
      >subscribe</a> to our monthly newsletter.
    </h4>
    <div class="articles" v-if="ob">
      <Story v-for="(story, index) in ob" :key="'story-' + index" :story="story"></Story>
    </div>
    <div class="ButtonWrap">
      <Button
        :clickFunction="loadMore"
        inverse
        center
        text="More News"
        auto
        pb
        v-scroll-reveal="{ opacity: 0 }"
      />
    </div>
  </div>
</template>

<script>
import Story from "@/molecules/Story.vue";
import Button from "@/atoms/Button.vue";
import axios from "axios";

export default {
  name: "News",
  components: { Story, Button },
  props: {
    limit: {
      type: Number,
      default: 10,
    },
  },
  data: function () {
    return {
      ob: [],
      headers: null,
      page: 1,
    };
  },
  mounted: function () {
    this.fetchPosts();
  },
  methods: {
    loadMore: function () {
      if (window.location.pathname !== "/news") window.location.href = "/news";
      else {
        this.page++;
        this.fetchPosts(this.page);
      }
    },
    fetchPosts: function (page = 1) {
      var per_page = window.location.pathname === "/news" ? 10 : 3;
      axios
        .get(this.apiURL + "?page=" + page + "&per_page=" + per_page)
        .then((response) => {
          this.headers = response.headers;
          this.ob = this.ob.concat(response.data);
        })
        .catch((error) => console.log(error));

      if (page == 1) this.$scrollTo("#app", 1000, {});
    },
  },
  computed: {
    apiURL: function () {
      return window.API + "/wp/v2/posts";
    },
    totalPosts: function () {
      return (this.headers && this.headers["x-wp-total"]) || 9999;
    },
    totalPages: function () {
      return (this.headers && this.headers["x-wp-totalpages"]) || 999;
    },
  },
};
</script>

<style scoped lang="scss">
.News {
  @extend .limit;
  @extend .destruct;

  .ButtonWrap {
    text-align: center;
  }

  h2 {
    letter-spacing: 0.06125em;
    font-weight: 800;
    color: $blue;
    font-size: 28px;
    @extend .limit;
    @extend .destruct;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  h4 a {
    color: $blue;
  }

  .Button {
    margin-top: 0px;
    margin-bottom: 64px;
    font-size: 16px;
  }
}
</style>
