<template>
  <div class="BurgerIcon" @click="toggleFunction()">
    <svg
      data-v-1550392b
      data-v-118134f4
      width="90px"
      height="91px"
      viewBox="0 0 90 91"
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      xmlns:xlink="https://www.w3.org/1999/xlink"
      class="hamburger"
    >
      <desc data-v-1550392b>Created with Sketch.</desc>
      <defs data-v-1550392b />
      <g
        data-v-1550392b
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="#ffffff"
        fill-rule="evenodd"
      >
        <g
          data-v-1550392b
          id="Desktop-HD"
          transform="translate(-1067.000000, -27.000000)"
          fill="#ffffff"
          fill-rule="nonzero"
        >
          <g
            data-v-1550392b
            id="noun_547160_cc"
            transform="translate(1067.000000, 27.000000)"
          >
            <path
              data-v-1550392b
              fill="#ffffff"
              d="M60.663,28.429 L29.337,28.429 C27.223,28.429 25.492,29.945 25.492,31.798 C25.492,33.65 27.222,35.166 29.337,35.166 L60.663,35.166 C62.777,35.166 64.508,33.649 64.508,31.798 C64.508,29.944 62.777,28.429 60.663,28.429 Z"
              id="Shape"
            />
            <path
              data-v-1550392b
              fill="#ffffff"
              d="M60.663,55.375 L29.337,55.375 C27.223,55.375 25.492,56.891 25.492,58.744 C25.492,60.596 27.222,62.113 29.337,62.113 L60.663,62.113 C62.777,62.113 64.508,60.596 64.508,58.744 C64.508,56.891 62.777,55.375 60.663,55.375 Z"
              id="Shape"
            />
            <path
              data-v-1550392b
              fill="#ffffff"
              d="M60.663,41.902 L29.337,41.902 C27.223,41.902 25.492,43.418 25.492,45.27 C25.492,47.122 27.222,48.638 29.337,48.638 L60.663,48.638 C62.777,48.638 64.508,47.122 64.508,45.27 C64.508,43.418 62.777,41.902 60.663,41.902 Z"
              id="Shape"
            />
            <path
              data-v-1550392b
              d="M45,0.27 C20.187,0.27 0,20.458 0,45.27 C0,70.082 20.187,90.27 45,90.27 C69.813,90.27 90,70.082 90,45.27 C90,20.458 69.813,0.27 45,0.27 Z M45,84.989 C23.099,84.989 5.28,67.171 5.28,45.27 C5.28,23.369 23.099,5.551 45,5.551 C66.901,5.551 84.72,23.369 84.72,45.27 C84.72,67.171 66.901,84.989 45,84.989 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "BurgerIcon",
  props: {
    toggleFunction: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.BurgerIcon {
  display: none;
  opacity: 1;
  cursor: pointer;
  transition: all 0.4s;
  position: absolute;
  top: -4px;
  right: 12px;
  z-index: 1001;
  width: 30px;

  svg {
    width: 30px;
  }

  @include smx {
    display: block;
  }
}
</style>
