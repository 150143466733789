<template>
  <nav class="ResourcesBar" id="Resources">
    <h5>Resources</h5>
    <ul>
      <template v-for="(link, index) in links">
        <li
          :class="{ hidden: hidden.includes(link.title) }"
          :key="'resource-' + index"
          v-scroll-reveal="{
            delay: 500 + 160 * index,
            distance: '0px',
            origin: 'left',
          }"
        >
          <a
            v-if="overrides && overrides[link.slug + '_link_override'] != ''"
            :href="overrides[link.slug + '_link_override']"
          >
            <img :src="require(`@/assets/${link.image}`)" :alt="link.title" />
            <span>{{ link.name }}</span>
          </a>
          <a
            v-else-if="link.target != null && link.target != ''"
            :href="linkBase + slug + link.href"
            :target="link.target"
          >
            <img :src="require(`@/assets/${link.image}`)" :alt="link.title" />
            <span>{{ link.name }}</span>
          </a>
          <a
            v-else-if="(slug !== null) & (slug !== '')"
            @click.prevent="
              modalLink(
                linkBase +
                  (theTags.length > 0 ? TAG_INDEXES[theTags[0]] : 'noTag') +
                  link.href
              )
            "
            :href="linkBase + slug + link.href"
          >
            <img :src="require(`@/assets/${link.image}`)" :alt="link.title" />
            <span>{{ link.name }}</span>
          </a>
          <a v-else :href="linkBase + link.href.replace('+', '')">
            <img :src="require(`@/assets/${link.image}`)" :alt="link.title" />
            <span>{{ link.name }}</span>
          </a>
        </li>
      </template>
    </ul>
    <modal
      name="resourceModal"
      :scrollable="false"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      transition="fade"
    >
      <button class="modalPopOutButton" @click="popout('resourceModal')">
        <i class="fa fa-arrow-up" style="transform: rotate(45deg);"></i>
      </button>
      <button class="modalCloseButton" @click="$modal.hide('resourceModal')">X</button>
      <div v-if="modalContent && modalContentSrc">
        <iframe :src="modalContentSrc"></iframe>
      </div>
    </modal>
  </nav>
</template>

<script>
import Logo from "@/atoms/Logo.vue";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "ResourcesBar",
  components: { Logo, Navigation },
  props: {
    theTags: {
      type: Array,
      default: () => []
    },
    hidden: {
      type: Array,
      default: () => []
    },
    overrides: {
      type: Object,
      default: null
    },
    slug: {
      type: String,
      default: null
    }
  },
  methods: {
    modalLink: function(href) {
      this.modalContent = true;
      this.modalContentSrc = href;
      this.show();
    },
    beforeOpen(event) {
      document.body.classList.add("stopScrolling");
    },
    beforeClose(event) {
      document.body.classList.remove("stopScrolling");
    },
    show() {
      this.$modal.show("resourceModal");
    },
    hide() {
      this.$modal.hide("resourceModal");
      this.modalContent = false;
      this.modalContentSrc = null;
    },
    popout(e) {
      window.open(this.modalContentSrc, "_blank");
      this.$modal.hide("resourceModal");
      this.modalContent = false;
      this.modalContentSrc = null;
    },
  },
  data: function() {
    return {
      modalContent: false,
      modalContentSrc: null,
      linkBase: "https://update.carlsonsw.com/redirect/?",
      links: [
        {
          name: "Downloads",
          title: "1",
          slug: "downloads",
          href: "+downloads",
          target: "_blank",
          route: "About",
          image: "img/ql2.png",
          active: false
        },
        {
          name: "Brochures and Docs",
          title: "2",
          slug: "brochures",
          href: "+brochures",
          target: "_blank",
          route: "About",
          image: "img/ql1.png",
          active: false
        },
        {
          name: "Knowledge Base",
          title: "3",
          slug: "knowledgebase",
          href: "+kb",
          route: "About",
          image: "img/ql3.png",
          active: false
        },
        {
          name: "Manuals",
          title: "4",
          slug: "manuals",
          href: "+manuals",
          route: "About",
          image: "img/ql4.png",
          active: false
        },
        {
          name: "Movies",
          title: "5",
          slug: "movies",
          href: "+movies",
          target: "_blank",
          route: "About",
          image: "img/ql5.png",
          active: false
        },
        {
          name: "Product Registration",
          title: "6",
          slug: "product_registration",
          href: "+registration",
          route: "About",
          image: "img/ql6.png",
          active: false
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.ResourcesBar {
  background: $navy;
  text-align: center;
  color: $light;
  z-index: 10;
  padding-top: 16px;
  padding-bottom: 56px;
  right: 0;

  @include smx {
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  iframe {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    min-height: 80vh;
  }

  h5 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    padding-top: 28px;
    padding-bottom: 48px;
    span {
      display: none;
    }

    @include smx {
      display: inline-block;
      br {
        display: none;
      }
      span {
        display: inline-block;
      }
    }
  }

  li {
    display: inline-block;
    vertical-align: middle;
    width: 155px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    list-style: none;
    text-align: center;
    padding-bottom: 12px;
    @include smx {
      display: inline-block;
      vertical-align: middle;
      padding-bottom: 40px;
      width: 50%;
    }

    &.hidden {
      display: none;
    }

    a {
      display: block;
      text-decoration: none;
    }

    span {
      padding-top: 8px;
      display: block;
      font-size: 14px;
      width: 100%;
      text-align: center;
    }

    img {
      display: inline-block;
      height: 28px;
      transition: all 0.4s;
      opacity: 1;
      @include smx {
        display: inline-block;
        width: auto;
        height: 18px;
      }

      &:hover {
        opacity: 0.5;
        transition: all 0.4s;
      }
    }
  }
}

.modalPopOutButton {
  position: absolute;
  top: 0px;
  right: 45px;
  width: 40px;
  height: 40px;
  background: #313131;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 100%;
  font-size: 16px;
  transition: all 0.5s;
  cursor: pointer;
  border: 2px solid white;

  &:hover {
    transition: all 0.5s;
    border: 2px solid #ffaaaa;
    color: #ffaaaa;
  }
}
</style>
