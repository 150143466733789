<template>
  <div class="Message">
    <section v-if="is404" :class="{ invisible: invisible }">
      <QuickLinks />
      <h2>We couldn't find what you were looking for...</h2>
      <p>
        Try returning to the
        <a href="/">homepage!</a>
      </p>
    </section>
    <div class="MegaNavContainer">
      <div class="pr">
        <MegaNav></MegaNav>
      </div>
    </div>
  </div>
</template>

<script>
import QuickLinks from "@/components/QuickLinks.vue";
import MegaNav from "@/components/MegaNav.vue";

export default {
  components: { QuickLinks, MegaNav },
  data: function() {
    return {
      invisible: true
    };
  },
  mounted: function() {
    setTimeout(() => {
      this.invisible = false;
    }, 2000);
  },
  props: {
    is404: Boolean
  }
};
</script>

<style lang="scss" scoped>
.Message {
  section {
    background: white;
    padding: 100px 40px;
    min-height: 250px;
    opacity: 1;
    transition: opacity 0.5s;

    h2 {
      color: $blue;
      padding-bottom: 24px;
    }

    &.invisible {
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
  .MegaNavContainer {
    background: $midnight;
    .pr {
      position: relative;
      max-width: 825px;
      margin: 0 auto;

      /deep/ .MegaNav {
        padding-top: 96px;
        padding-bottom: 330px !important;
        position: static !important;
        border: none;
      }
    }
  }
}
</style>
