var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PageTemplate PageTemplateWithWebPartial"},[_c('Header',[(_vm.apiResult)?[_c('PageSubNav',{attrs:{"title":_vm.apiResult.title.rendered,"items":_vm.apiResult.acf.subnav_items || []}}),(_vm.apiResult.acf.page_hero_video)?_c('Hero',{attrs:{"slides":[
          {
            hero_slide_movie: _vm.apiResult.acf.page_hero_video,
            hero_slide_title: _vm.apiResult.title.rendered,
            hero_slide_text: _vm.apiResult.acf.page_tagline,
          } ]}}):(_vm.apiResult.acf.page_hero_image)?_c('SimpleHero',{attrs:{"src":_vm.apiResult.acf.page_hero_image,"title":_vm.apiResult.title.rendered}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.apiResult.acf.page_tagline)}})]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"mainWrap"},[(_vm.apiResult)?_c('Main',{class:_vm.apiResult.slug || '',attrs:{"id":"intro"}},[_c('TwoUpStatic',{attrs:{"backgroundColor":_vm.COLORS.$lightestGrey,"src":_vm.apiResult.featured_img_url || '',"title":_vm.apiResult.acf.overview_title_override
            ? _vm.apiResult.acf.overview_title_override
            : 'Overview'}},[(
            _vm.apiResult.acf.overview_title_override &&
              _vm.apiResult.acf.overview_title_override == 'Find a Dealer'
          )?_c('LocateDealer',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ opacity: 0 }),expression:"{ opacity: 0 }"}],attrs:{"id":"dealer"}}):_vm._e(),(_vm.apiResult.webPartial)?_c('div',{attrs:{"id":"Overview"},domProps:{"innerHTML":_vm._s(_vm.apiResult.webPartial)}}):_vm._e()],1),(_vm.apiResult.acf.include_resources_bar)?_c('ResourcesBar'):_vm._e(),(_vm.apiResult.acf && _vm.apiResult.acf.feature_blocks)?_c('FeatureBlocks',{attrs:{"blocks":_vm.apiResult.acf.feature_blocks}}):_vm._e(),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ opacity: 0 }),expression:"{ opacity: 0 }"}],staticClass:"YoutubeVideoWrap cf"},[_c('YoutubeVideo',{attrs:{"half":"","src":_vm.apiResult.acf.page_video}})],1),_c('LocateDealer',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ opacity: 0 }),expression:"{ opacity: 0 }"}],attrs:{"id":"dealer"}}),_c('Testimonials',{attrs:{"fields":_vm.apiResult.acf.testimonial,"id":"testimonials"}})],1):[_c('Main',[_c('Message',{attrs:{"is404":""}})],1)]],2),_c('Footer',{attrs:{"noImage":"","id":"footer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }