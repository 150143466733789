<template>
  <nav class="QuickLinks">
    <h5>Quick<span>&nbsp;</span><br />Links</h5>
    <ul>
      <li v-for="(link, index) in links" :key="'quickLink-' + index">
        <a class="quickLink" :href="link.href" :title="link.title" target="_blank">
          <img :src="require(`@/assets/${link.image}`)" :alt="link.title" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Logo from "@/atoms/Logo.vue";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "QuickLinks",
  components: { Logo, Navigation },
  mounted: function() {
    document.body.classList.add("hasQL");
  },
  beforeDestroy: function() {
    document.body.classList.remove("hasQL");
  },
  data: function() {
    return {
      links: [
        {
          title: "Contact Sales",
          href: "https://account.carlsonsw.com/contact",
          image: "img/ql1.png",
          active: false,
        },
        {
          title: "Downloads",
          href: "https://update.carlsonsw.com/redirect/?downloads",
          image: "img/ql2.png",
          active: false,
        },
        {
          title: "Knowledge Base",
          href: "https://update.carlsonsw.com/redirect/?kb",
          image: "img/ql3.png",
          active: false,
        },
/*
        {
          title: "Manuals",
          href: "https://update.carlsonsw.com/redirect/?manuals",
          image: "img/ql4.png",
          active: false,
        },
*/
        {
          title: "Movies",
          href: "https://update.carlsonsw.com/redirect/?movies",
          image: "img/ql5.png",
          active: false,
        },
/*
        {
          title: "Product Registration",
          href: "https://update.carlsonsw.com/decode_xml.php",
          image: "img/ql6.png",
          active: false,
        },
*/
        {
          title: "Request a Demo",
          href: "https://account.carlsonsw.com/demo",
          image: "img/ql7.png",
          active: false,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.QuickLinks {
  background: $navy;
  text-align: center;
  color: $light;
  width: 65px;
  position: fixed;
  z-index: 10;
  top: 30vh;
  padding-top: 16px;
  padding-bottom: 20px;
  right: 0;
  border: 1px solid white;
  border-right: 0;
  @include smx {
    border-right: 1px solid white;
  }

  @include smx {
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
  }

  h5 {
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 20px;
    span {
      display: none;
    }

    @include smx {
      display: inline-block;
      br {
        display: none;
      }
      span {
        display: inline-block;
      }
    }
  }

  li {
    list-style: none;
    text-align: center;
    padding-bottom: 12px;
    @include smx {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 7);
    }

    img {
      display: inline-block;
      width: 24px;
      transition: all 0.4s;
      opacity: 1;
      @include smx {
        display: inline-block;
        width: auto;
        height: 18px;
      }

      &:hover {
        opacity: 0.5;
        transition: all 0.4s;
      }
    }
  }
}
</style>
