<template>
  <div class="ImageSpinner">
    <img class="dummy" :src="require(`@/assets/${dummy}`)" />
    <div class="spin" v-for="(slide, index) in slides" :key="'slide-' + index">
      <transition name="fade">
        <img v-if="showingSlide == index" class="image" :src="slide" />
      </transition>
    </div>
    <div class="controls">
      <span
        :class="{ active: showingSlide == index }"
        v-for="(slide, index) in slides"
        :key="'thumb-' + index"
        @click="showSlide(index)"
      >
        &nbsp;</span
      >
    </div>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";

export default {
  name: "ImageSpinner",
  components: { Rule },
  props: {
    color: {
      type: String,
    },
    imageSet: {
      type: Array,
    },
  },
  mounted: function() {
    this.autoSlide();
  },
  data() {
    return {
      dummy: "img/_Carlson_Quarry_023.jpg",
      timeInterval: 4000,
      delayInterval: 10000,
      showingSlide: 0,
      defaultSlides: [
        "img/_Carlson_Quarry_023.jpg",
        "img/_Carlson_Quarry_027.jpg",
        "img/_Carlson_Quarry_046.jpg",
        "img/_Carlson_Quarry_010.jpg",
        "img/_Carlson_Quarry_035.jpg",
      ],
    };
  },
  computed: {
    slides: function() {
      return this.imageSet;
    },
  },
  methods: {
    showSlide: function(num) {
      this.clear();
      this.showingSlide = num;
    },
    autoSlide: function() {
      this.interval = setInterval(() => {
        if (this.showingSlide < this.slides.length - 1) {
          this.showingSlide++;
        } else {
          this.showingSlide = 0;
        }
      }, this.timeInterval);
    },
    clear: function() {
      clearInterval(this.interval);
      setTimeout(() => {
        this.autoSlide();
      }, this.delayInterval);
    },
  },
};
</script>

<style scoped lang="scss">
.ImageSpinner {
  position: relative;

  width: 32%;
  float: right;
  display: block;
  padding-bottom: 16px;
  @extend .p0;

  @include smx {
    width: 100% !important;
  }

  .dummy {
    visibility: hidden;
    width: 100%;
    position: relative;
    z-index: 1;
    max-height: 330px;
  }

  .controls {
    position: absolute;
    top: 0px;
    left: -48px;

    @include smx {
      left: 0px;
    }

    span {
      display: block;
      cursor: pointer;
      border-radius: 100px;
      width: 14px;
      height: 14px;
      border: 2px solid $navy;
      transition: all 0.4s;
      margin-bottom: 16px;
      opacity: 0.4;
      @include smx {
        height: 32px;
        width: 32px;
        margin-bottom: 40px;
      }

      &.active {
        background: $navy;
        transition: all 0.4s;
      }

      &:hover {
        background: $navy;
        transition: all 0.4s;
        opacity: 1;
      }
    }
  }
  .image {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;

    @include smx {
      width: calc(100% - 48px);
    }
  }
}
</style>
