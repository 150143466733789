<template>
  <div class="Circles">
    <div>
      <a href="#">
        <img src="@/assets/img/headset.png" />
      </a>
      <h5>Contact<br />Tech Support</h5>
    </div>

    <div>
      <a href="#">
        <img class="hat" src="@/assets/img/hat.png" />
      </a>
      <h5>Browse<br />By Industry</h5>
    </div>

    <div>
      <a href="#">
        <img src="@/assets/img/survey.png" />
      </a>
      <h5>Purchase<br />Our Products</h5>
    </div>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule.vue";

export default {
  name: "Circles",
  components: { Rule },
};
</script>

<style scoped lang="scss">
.Circles {
  @extend .limit;
  @extend .cf;
  padding-bottom: 96px;
  width: 100%;
  overflow: hidden;

  div {
    margin-top: 64px;
    width: 33.3%;
    text-align: center;
    float: left;
    position: relative;

    &:after {
      content: "";
      background: #555;
      width: 1200px;
      position: absolute;
      left: -600px;
      height: 12px;
      top: 35%;
      z-index: 1;
      @include smx {
        top: 19%;
      }
    }

    a {
      display: inline-block;
      border-radius: 132px;
      width: 132px;
      height: 132px;
      background: $navy;
      @extend .hovBg;
      position: relative;
      z-index: 2;
      @include smx {
        width: 90px;
        height: 90px;
      }

      img {
        width: 60px;
        display: block;
        margin: 32px auto 0;
        @include smx {
          width: 40px;
          margin-top: 18px;
        }

        &.hat {
          margin: 42px auto 0;
          @include smx {
            margin-top: 24px;
          }
        }
      }
    }

    h5 {
      margin-top: 12px;
      color: $blue;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      @extend .got;
      @include smx {
        font-size: 14px;
      }
    }
  }
}
</style>
