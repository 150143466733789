<template>
  <div class="Hero">
    <img class="dummy" src="@/assets/img/sur.png" />

    <transition-group name="fade">
      <template v-for="(slide, index) in slides">
        <video
          v-if="slide.hero_slide_movie"
          muted="muted"
          autoplay="autoplay"
          loop
          class="slide videoslide"
          :key="'slide-movie-' + index"
        >
          <source :src="slide.hero_slide_movie" type="video/mp4" />
        </video>
        <img
          v-if="slide.hero_slide_image && showingSlide == index"
          class="slide"
          :src="slide.hero_slide_image"
          :key="'slide-img-' + index"
        />
      </template>
    </transition-group>

    <div
      class="text"
      :style="slides[showingSlide].hero_slide_link_url && 'cursor:pointer'"
      @click="goToSlideUrl()"
    >
      <a :href="slides[showingSlide].hero_slide_link_url">
        <h3>{{ slides[showingSlide].hero_slide_title }}</h3>
        <p>{{ slides[showingSlide].hero_slide_text }}</p>
      </a>
    </div>

    <div class="bar" v-if="slides.length > 1">
      <ul>
        <!--
        <li class="prevButton" @click="prevSlide()">{{'<<'}}</li>
        -->
        <li
          v-for="(slide, index) in slides"
          :key="'slide+' + index"
          @click="showSlide(index)"
          :class="showingSlide == index ? 'active' : ''"
        >{{ slide.hero_slide_button_text }}</li>
        <!--
        <li class="nextButton" @click="nextSlide()">{{'>>'}}</li>
        -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    msg: String,
    slides: Array,
  },
  mounted: function () {
    var images = [];
    var videos = [];
    this.slides.forEach((slide) => {
      if (slide.hero_slide_image) {
        const image = new Image();
        image.src = slide.hero_slide_image;
        images.push(image);
      }
    });
    this.autoInterval = window.setInterval(() => {
      this.autoIncrement();
    }, 5200);
  },
  data() {
    return {
      showingSlide: 0,
      autoInterval: null,
    };
  },
  methods: {
    isMob: function () {
      return window.outerWidth < 1025;
    },
    nextSlide: function () {
      var newNum = (this.showingSlide + 1) % this.slides.length;
      this.showingSlide = newNum;
      this.destroyAuto();
    },
    prevSlide: function () {
      var newNum = this.showingSlide - 1;
      if (newNum < 0) newNum = this.slides.length - 1;
      this.showingSlide = newNum;
      this.destroyAuto();
    },
    showSlide: function (num) {
      if (!this.isMob()) {
        this.showingSlide = num;
        this.destroyAuto();
      } else {
        window.location = this.slides[num].hero_slide_link_url;
      }
    },
    autoIncrement: function () {
      var nextNum = (this.showingSlide + 1) % this.slides.length;
      this.showingSlide = nextNum;
    },
    destroyAuto: function () {
      this.autoInterval && window.clearInterval(this.autoInterval);
      this.autoInterval = null;
    },
    goToSlideUrl: function () {
      if (this.slides[this.showingSlide].hero_slide_link_url) {
        window.location = this.slides[this.showingSlide].hero_slide_link_url;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Hero {
  position: relative;
  max-height: 695px;
  margin: 0 auto;

  .dummy {
    visibility: hidden;
    max-height: 695px;
    padding-bottom: 72px;
    width: 100%;
    position: relative;
    z-index: 1;
    object-fit: cover;
    object-position: center center;
    @include smx {
      display: none;
    }
    @include med {
      display: none;
    }
  }

  .slide {
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
    top: 0;
    left: 0;
    z-index: 1;
    max-height: 695px;

    &:not(.videoslide) {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    @include smx {
      position: static;
    }
    @include med {
      position: static;
    }
  }

  .videoslide {
    max-height: none;
  }

  .text {
    padding: 100% 0px 192px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background-image: linear-gradient(
      0deg,
      rgba(31, 51, 83, 0.86) 10%,
      rgba(32, 143, 204, 0) 20%
    );
    @include smx {
      display: none;
    }
    @include med {
      display: none;
    }

    a {
      text-decoration: none;
    }

    h3 {
      position: relative;
      top: 100px;
      color: $blue;
      text-transform: uppercase;
      font-size: 28px;
      @extend .limit;
    }

    p {
      position: relative;
      top: 80px;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      @extend .limit;
    }
  }

  .bar {
    position: absolute;
    z-index: 4;
    bottom: 2px;
    width: 100%;
    background: $navy;
    @include smx {
      position: static;
    }
    @include med {
      position: static;
    }

    ul {
      li {
        user-select: none;
        @extend .destruct;
        padding: 16px 40px;
        width: 25%;
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.125em;
        text-align: center;
        border-right: 1px solid $grey;
        transition: all 0.4s;
        font-weight: 400;
        opacity: 1;
        cursor: pointer;
        position: relative;
        background: transparent;

        &:after {
          position: absolute;
          bottom: 20%;
          left: 25%;
          content: "";
          display: block;
          width: 0%;
          height: 1px;
          background-color: white;
        }

        &.active {
          opacity: 1;
          font-weight: bold;
          background: $blue-o;
          transition: all 0.4s;
          &:after {
            transition: all 0.4s;
            width: 50%;
          }
        }

        &.prevButton,
        &.nextButton {
          width: 12.5% !important;
          letter-spacing: -1px;
          font-weight: bold;
          border-top: 0 !important;
          opacity: 1 !important;
          &:after {
            display: none !important;
          }
          @include med {
            display: none;
          }
        }

        &:last-of-type {
          border-right: 0;
        }

        &:hover {
          transition: all 0.4s;
          background: $blue;
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }

  @include med {
    &.Hero > span,
    &.Hero > span > img {
      position: absolute !important;
      top: 0 !important;
      z-index: 5;
    }
    &.Hero .dummy {
      display: block !important;
      pointer-events: none;
      opacity: 0;
    }

    &.Hero .bar {
      z-index: 6;
      position: relative;
    }
  }
}
</style>
