const STACK_BLURBS = {
  LAND_DEVELOPMENT: {
    EXAMPLE_BLURB:
      "Just add text here! Make sure you stay within the double quotes :D - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste dolores cupiditate dolorum quidem voluptas, id, placeat qui beatae repellendus minus delectus deserunt, deleniti harum in. At recusandae quos veniam accusantium?",
    OFFICE_SOFTWARE: "",
    DATA_COLLECTION: "",
    INSTRUMENTS: "",
    MACHINE_CONTROL: "",
  },
  MINING: {
    OFFICE_SOFTWARE: "",
    DATA_COLLECTION: "",
    INSTRUMENTS: "",
    MACHINE_CONTROL: "",
  },
  WASTE_MANAGEMENT: {
    OFFICE_SOFTWARE: "",
    DATA_COLLECTION: "",
    INSTRUMENTS: "",
    MACHINE_CONTROL: "",
  },
  // OFFICE_SOFTWARE: {
  //   LAND_DEVELOPMENT: "",
  // },

  /*
  This allows you to add blurbs to the different sections on product pages.
  
  If you go to /stack/land-development, as you scroll down the page, you'll see 
  sections for LAND DEVELOPMENT - Office Software, LAND DEVELOPMENT - Data Collection, etc. 
  
  Use the text above to add text underneath those headings. So if you change the text on line 7 for example, 
  whatever you write will show up under the heading for the LAND DEVELOPMENT - Instruments section.
  
  It's currently set so that if you instead look at the collections by Solution/Product Family first, 
  instead of industry—for example, at /stack/office-software/—it will use the corresponding text from the inverse group. 
  For example, if you look at /stack/office-software, you'll see sections for OFFICE SOFTWARE - Land Development, etc. 
  If STACK_BLURBS.OFFICE_SOFTWARE.LAND_DEVELOPMENT isn't set, it will try to use 
  STACK_BLURBS.LAND_DEVELOPMENT.OFFICE_SOFTWARE instead. 
  
  If you'd rather it showed nothing, specify the blurb as an empty string, like in the commented out example on lines 22-24 above.
  */
};

export { STACK_BLURBS };
