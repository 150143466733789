<template>
  <div class="PageTemplate">
    <Header>
      <template v-if="apiResult">
        <PageSubNav
          :title="apiResult.title.rendered"
          :items="apiResult.acf.subnav_items || []"
        />
        <Hero
          v-if="apiResult.acf.page_hero_video"
          :slides="[
            {
              hero_slide_movie: apiResult.acf.page_hero_video,
              hero_slide_title: apiResult.title.rendered,
              hero_slide_text: apiResult.acf.page_tagline,
            },
          ]"
        ></Hero>
        <SimpleHero
          v-else-if="apiResult.acf.page_hero_image"
          :src="apiResult.acf.page_hero_image"
          :title="apiResult.title.rendered"
        >
          <span v-html="apiResult.acf.page_tagline"></span>
        </SimpleHero>
      </template>
    </Header>
    <div class="mainWrap">
      <Main v-if="apiResult" id="intro" :class="apiResult.slug || ''" >
        <TwoUpStatic
          :backgroundColor="COLORS.$lightestGrey"
          :src="apiResult.featured_img_url || ''"
          :title="
            apiResult.acf.overview_title_override
              ? apiResult.acf.overview_title_override
              : 'Overview'
          "
        >
          <LocateDealer
            v-if="
              apiResult.acf.overview_title_override &&
                apiResult.acf.overview_title_override == 'Find a Dealer'
            "
            v-scroll-reveal="{ opacity: 0 }"
            id="dealer"
          />
          <div
            id="Overview"
            v-if="apiResult.acf.page_intro"
            v-html="apiResult.acf.page_intro"
          ></div>
        </TwoUpStatic>

        <ResourcesBar v-if="apiResult.acf.include_resources_bar" />

        <FeatureBlocks
          v-if="apiResult.acf && apiResult.acf.feature_blocks"
          :blocks="apiResult.acf.feature_blocks"
        />

        <News :limit="3" v-if="apiResult.acf.include_news_feed"></News>

        <div class="YoutubeVideoWrap cf" v-scroll-reveal="{ opacity: 0 }">
          <YoutubeVideo half :src="apiResult.acf.page_video" />
        </div>

        <LocateDealer v-scroll-reveal="{ opacity: 0 }" id="dealer" />

        <Testimonials :fields="apiResult.acf.testimonial" id="testimonials" />
      </Main>
      <template v-else>
        <Main>
          <Message is404 />
        </Main>
      </template>
    </div>
    <Footer noImage id="footer" />
  </div>
</template>

<script>
import Button from "@/atoms/Button";
import Rule from "@/atoms/Rule";

import LocateDealer from "@/molecules/LocateDealer";
import PurchaseBlock from "@/molecules/PurchaseBlock";
import YoutubeVideo from "@/molecules/YoutubeVideo";
import TwoUpStatic from "@/molecules/TwoUpStatic";
import TwoUp from "@/molecules/TwoUp";
import PageSubNav from "@/molecules/PageSubNav";
import Message from "@/molecules/Message";
import FeatureBlocks from "@/molecules/FeatureBlocks";
import Hero from "@/molecules/Hero";
import SimpleHero from "@/molecules/SimpleHero";
import ResourcesBar from "@/components/ResourcesBar";

import Header from "@/components/Header";
import Main from "@/components/Main";
import Footer from "@/components/Footer";
import ProductNav from "@/components/ProductNav";
import Testimonials from "@/components/Testimonials";
import News from "@/components/News.vue";

import axios from "axios";

export default {
  name: "PageTemplate",
  components: {
    Header,
    Hero,
    ResourcesBar,
    SimpleHero,
    PurchaseBlock,
    LocateDealer,
    YoutubeVideo,
    Button,
    Rule,
    Message,
    PageSubNav,
    FeatureBlocks,
    TwoUp,
    TwoUpStatic,
    Main,
    Footer,
    ProductNav,
    Testimonials,
    News
  },
  data() {
    return {
      apiResult: null,
    };
  },
  computed: {
    slug: function() {
      const params = this.$route.params;
      // return this.$route.params.pageSlug;
      return params.childSlug ? params.childSlug : params.pageSlug;
    },
  },
  mounted: function() {
    axios
      .get(window.API + "/wp/v2/pages?embed&slug=" + this.slug)
      .then((response) => (this.apiResult = response.data[0]))
      .catch((error) => console.log(error));
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.PageTemplate {
  .YoutubeVideoWrap {
    background: #212121;

    @include xlo {
      padding-left: 128px;
      padding-right: 128px;
    }
  }
  .mainWrap {
    min-height: 500px;
    background: #313131;
  }
}
</style>
