<template>
  <div
    class="TwoUp"
    :class="{ layoutReverse: layoutReverse }"
    :style="'background-color: ' + backgroundColor || 'white'"
  >
    <div>
      <h1 :id="title" class="title" v-if="title">{{ title }}</h1>
      <div class="text">
        <li v-for="(item, index) in normalizedItems" :key="'item-' + index">
          <h2 v-if="item.heading">{{ item.heading }}</h2>
          <p :class="{ bullet: !item.heading }">{{ item.text }}</p>
        </li>
      </div>
      <ImageSpinner v-if="imageSet" :imageSet="imageSet"></ImageSpinner>
      <img v-if="singleImage" class="image" :src="singleImage" />
      <Rule v-if="!layoutReverse && includeRule" center pb></Rule>
    </div>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";
import Button from "@/atoms/Button";
import ImageSpinner from "@/molecules/ImageSpinner";

export default {
  name: "TwoUp",
  components: { Rule, Button, ImageSpinner },
  data() {
    return {
      placeholderItems: [],
    };
  },
  props: {
    includeRule: {
      type: Boolean,
    },
    backgroundColor: {
      type: String,
    },
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    singleImage: {
      type: String,
    },
    imageSet: {
      type: Array,
    },
    layoutReverse: {
      type: Boolean,
    },
  },
  computed: {
    normalizedItems: function() {
      if (this.items) {
        if (typeof this.items[0] == "object") {
          return this.items;
        } else {
          return this.items.map((item) => {
            return { text: item };
          });
        }
      } else {
        return this.placeholderItems;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.TwoUp {
  padding-top: 72px;
  > div {
    @extend .cf;
    @extend .limit;
    @extend .destruct;
    padding-bottom: 72px;
  }

  .Rule {
    clear: both;
    padding-bottom: 48px;
  }

  .Button {
    margin-bottom: 96px;
    @include smx {
      width: 100%;
    }
  }

  &.layoutReverse {
    .text {
      float: right;
      padding-left: 8% !important;
      h2 {
        padding-right: 0;
      }

      p {
        padding-right: 0;
      }
    }

    .image {
      float: left;
    }
  }

  .title {
    font-size: 24px;
    color: $blue;
    padding-bottom: 32px;
  }

  .text {
    float: left;
    max-width: 64%;
    width: 100%;
    @extend .p0;
    @include smx {
      max-width: 100%;
      h2,
      p {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    li {
      list-style: none;

      h2 {
        color: $blue;
        padding-bottom: 8px;
        @extend .destruct;
        padding-right: 25%;
        line-height: 1.55em;
        font-size: 20px;
      }
    }
  }

  .image {
    width: 32%;
    float: right;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 24px;
    @extend .p0;
    @include smx {
      width: 100% !important;
    }
  }

  .Rule {
    padding-top: 12px;
  }

  h3 {
    color: $navy;
    padding-bottom: 8px;
    @extend .destruct;
    line-height: 1.55em;
    letter-spacing: 0.06125em;
    cursor: pointer;
    @include funLink($blue);
    font-size: 32px;
    display: inline-block;
    &:hover {
      color: $blue;
    }
  }

  p {
    line-height: 32px;
    padding-bottom: 48px;
    @extend .destruct;
    padding-right: 25%;

    &.bullet {
      width: 100%;
      text-align: left;
      padding-bottom: 16px;

      &:before {
        content: "•";
        display: inline-block;
        margin-right: 16px;
      }
    }
  }

  .readMore {
    color: $blue;
    display: inline-block;
    margin-bottom: 64px;
    font-style: italic;
    padding: 8px 0 4px;
    @include funLink($blue);

    span {
      display: inline-block;
      @include arrow-right(5px, $blue);
      position: relative;
      top: 0px;
      left: 7px;
    }
  }
}
</style>
