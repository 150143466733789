<template>
  <div v-if="values" class="AdBlock" :style="'background-image: url(' + values.background_image + ')'">
    <div class="AdBlockInner">
      <div class="Drone left_image" :style="'background-image: url(' + values.left_image + ')'"></div>
      <div class="CPCLogo right_image" :style="'background-image: url(' + values.right_image + ')'"></div>
      <div class="Text">
        <div class="DI heading_text_top" v-html="values.heading_text_top" />
        <div class="TD heading_text_bottom" v-html="values.heading_text_bottom" />
      </div>
    </div>
    <div class="Buttons">
      <Button class="button_1" third :href="values.button_1_url" :text="values.button_1_text" />
      <Button class="button_2" third :href="values.button_2_url" :text="values.button_2_text" />
    </div>  
  </div>
</template>

<script>
// import Logo from "@/atoms/Logo.vue";
import Button from "@/atoms/Button.vue";
import axios from "axios";

export default {
  name: "AdBlock",
  components: { Button },
  // mounted: function() {
  //   document.body.classList.add("hasQL");
  // },
  // beforeDestroy: function() {
  //   document.body.classList.remove("hasQL");
  // },
  data: function() {
    return {
      links: [],
      acfOptions: null,
      values: null
    };
  },
  mounted: function() {
    axios.get(this.OPTIONS_ROUTE)
      .then((response) => {
        this.acfOptions = response.data.acf;
        this.values = this.acfOptions.adblock_fields
        let styleTag = document.createElement('style');
        styleTag.innerHTML = this.values.custom_styles
        document.body.appendChild(styleTag)
      })
      .catch((error) => console.log(error)); 
  }
};
</script>

<style scoped lang="scss">
.AdBlock {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background: $navy;
  text-align: center;
  color: $light;
  height: 320px;
  max-width: 1680px;
  margin: 0 auto;
 
  background-size: cover;
  border: 1px solid white;
  border-right: 0;
  position: relative;
  background-color: rgba(10, 10, 10, 0.5);
  background-blend-mode: darken;

  @include lrg {
   height: 280px;
  }

  @include med {
    height: auto;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
  }

 

  .Drone {
    width: 350px;
    height: 250px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position:absolute;
    top: 0;
    left: 0;

    @include lrg {
       width: 270px;
      height: 170px;
    }

    @include med {
      position: static;
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .CPCLogo {
    width: 300px;
    height: 100px;
    position:absolute;
    top: 28px;
    right: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    @include lrg {
      font-size: 56px;
    }

    @include med {
      position: static;
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .Text{
    width: 65%;
    margin: 0 auto;
    text-align: center;

    @include med {
      width: 90%;
      margin: 24px auto;
    }
  }

  .Buttons {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;

    @include med {
      position: static;
    }

    .Button {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      @include med {
        margin-top: 20px;
        margin-bottom: 0;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .DI {
    font-size: 64px;
    text-transform: uppercase;

    @include lrg {
      font-size: 48px;
    }

    @include med {
      font-size: 28px;
    }
  }
  .TD {
    font-size: 72px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: .7;

     @include lrg {
      font-size: 48px;
    }

    @include med {
      font-size: 28px;
    }
  }

  h5 {
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 20px;
    span {
      display: none;
    }

    @include smx {
      display: inline-block;
      br {
        display: none;
      }
      span {
        display: inline-block;
      }
    }
  }

  li {
    list-style: none;
    text-align: center;
    padding-bottom: 12px;
    @include smx {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 7);
    }

    img {
      display: inline-block;
      width: 24px;
      transition: all 0.4s;
      opacity: 1;
      @include smx {
        display: inline-block;
        width: auto;
        height: 18px;
      }

      &:hover {
        opacity: 0.5;
        transition: all 0.4s;
      }
    }
  }
}
</style>
