<template>
  <div class="SearchGrid">
    <transition name="fade">
      <div v-if="term === null && (results === null || results.length === 0)">
        Search above to see results!
      </div>
      <div
        v-else-if="term !== null && (results === null || results.length === 0)"
      >
        Loading...
      </div>
      <div v-else>
        <section>
          <h2 id="results">Results for {{ term }}:</h2>
          <h5>
            <span v-if="totalResultsCount"
              >Showing Results <strong>1 - {{ totalResultsCount }}</strong> of
              <strong>{{ totalResultsCount }}</strong></span
            >
          </h5>
          <section class="filters">
            <h5><strong>Filter</strong></h5>
            <span
              v-for="(type, index) in typesList"
              class="tag"
              :class="[
                checkForActive(type),
                type === 'all' || checkForTypeResults(type) ? '' : 'noResults',
              ]"
              :key="index + type"
              @click="toggleFilter(type)"
              >{{ type }}</span
            >
          </section>
          <section class="sort">
            <h5><strong>Sort</strong></h5>
            <span
              class="tag sortButton"
              :class="activeSort === 'relevance' ? 'active' : 'inactive'"
              @click="toggleSort('relevance')"
              >Relevance</span
            >
            <span
              class="tag sortButton"
              :class="activeSort === 'date' ? 'active' : 'inactive'"
              @click="toggleSort('date')"
              >Date</span
            >
          </section>
        </section>
        <ul>
          <!-- this is a copy of the wpResults section which gets shown at the top when date is the sort type -->
          <li
            class="SearchResult"
            v-if="wpResults.length && activeSort === 'date'"
          >
            <ul>
              <li
                class="SearchResultWP"
                :class="[subresult.subtype, checkForActive(subresult.subtype)]"
                v-for="(subresult, index) in sortByDate(wpResults)"
                :key="index"
              >
                <a
                  :href="
                    cleanURL(subresult.url, subresult.subtype, subresult.title)
                  "
                >
                  <h3>
                    {{ subresult.title | unescape }}

                    <br class="mobileOnly" />
                    <span class="tag">{{
                      formatSubtype(subresult.subtype)
                    }}</span>
                  </h3>
                  <strong class="date"
                    ><em>{{
                      formatDate(subresult.search_date) ||
                        formatDate("2012-10-10")
                    }}</em></strong
                  >
                  <em>{{
                    cleanURL(subresult.url, subresult.subtype, subresult.title)
                  }}</em>
                  <p>
                    {{ subresult.smart_excerpt || "Click to see more" }}
                  </p>

                  <img
                    v-if="subresult.featured_img_url"
                    :src="subresult.featured_img_url"
                  />
                </a>
              </li>
            </ul>
          </li>
          <li class="SearchResult" v-if="carlsonCustomResults.length">
            <ul>
              <li
                class="SearchResultCarlson"
                :class="[
                  determineSubtypeByURL(subresult.link),
                  checkForActive(determineSubtypeByURL(subresult.link)),
                ]"
                v-for="(subresult, index) in carlsonCustomResults"
                :key="index"
              >
                <a :href="subresult.link">
                  <h3>
                    {{ subresult.title }}
                    <br class="mobileOnly" />
                    <span class="tag">{{
                      formatSubtype(determineSubtypeByURL(subresult.link))
                    }}</span>
                  </h3>
                  <em>{{ subresult.link }}</em>
                </a>
              </li>
            </ul>
          </li>
          <li
            class="SearchResult"
            v-if="wpResults.length && activeSort !== 'date'"
          >
            <ul>
              <li
                class="SearchResultWP"
                :class="[subresult.subtype, checkForActive(subresult.subtype)]"
                v-for="(subresult, index) in wpResultsNewsLast"
                :key="index"
              >
                <a
                  :href="
                    cleanURL(subresult.url, subresult.subtype, subresult.title)
                  "
                >
                  <h3>
                    {{ subresult.title | unescape }}

                    <br class="mobileOnly" />
                    <span class="tag">{{
                      formatSubtype(subresult.subtype)
                    }}</span>
                  </h3>
                  <em>{{
                    cleanURL(subresult.url, subresult.subtype, subresult.title)
                  }}</em>
                  <p>
                    {{ subresult.smart_excerpt || "Click to see more" }}
                  </p>
                  <img
                    v-if="subresult.featured_img_url"
                    :src="subresult.featured_img_url"
                  />
                </a>
              </li>
            </ul>
          </li>
          <li class="SearchResult" v-if="carlsonResults.length">
            <ul>
              <li
                class="SearchResultCarlson"
                :class="[
                  determineSubtypeByURL(subresult.link),
                  checkForActive(determineSubtypeByURL(subresult.link)),
                ]"
                v-for="(subresult, index) in carlsonResults"
                :key="index"
              >
                <a :href="subresult.link">
                  <h3>
                    {{ subresult.title }}
                    <br class="mobileOnly" />
                    <span class="tag">{{
                      formatSubtype(determineSubtypeByURL(subresult.link))
                    }}</span>
                  </h3>
                  <em>{{ subresult.link }}</em>
                </a>
              </li>
            </ul>
          </li>
          <li class="SearchResult" v-if="carlsonYoutubeResults.length">
            <ul>
              <li
                class="SearchResultCarlson"
                :class="[
                  determineSubtypeByURL(subresult.link),
                  checkForActive(determineSubtypeByURL(subresult.link)),
                ]"
                v-for="(subresult, index) in carlsonYoutubeResults"
                :key="index"
              >
                <a :href="subresult.link">
                  <h3>
                    {{ subresult.title }}
                    <br class="mobileOnly" />
                    <span class="tag">{{
                      formatSubtype(determineSubtypeByURL(subresult.link))
                    }}</span>
                  </h3>
                  <em>{{ subresult.link }}</em>
                </a>
              </li>
            </ul>
          </li>
          <li class="buttonLi">
            <Button
              inverse
              half
              center
              text="Search for more results on the Carlson Knowledgebase"
              :href="
                'https://web.carlsonsw.com/files/knowledgebase/kbase05.php?action=search&phrase=' +
                  this.term
              "
              newTab
            ></Button>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import BigInput from "@/components/BigInput.vue";
import Button from "@/atoms/Button.vue";

export default {
  name: "SearchGrid",
  components: { BigInput, Button },
  data: function() {
    return {
      activeType: null,
      activeSort: "relevance",
      typesList: [
        "all",
        "news",
        "product",
        "knowledgebase",
        "video",
        "forum",
        "manual",
        "brochure",
        "collection",
      ],
    };
  },
  props: {
    results: {
      type: Array,
      default: [],
    },
    term: {
      type: String,
      default: null,
    },
  },
  mounted: function() {
    if (window.location.href.includes("#results")) {
      // console.log(this.$route);
      // window.history.replaceState({}, null, this.$route.fullPath);

      this.scrollToResults();
      // }, 400);
    } else {
      // window.setTimeout(() => {
      // this.$scrollTo("#results", 500, {});
      window.history.replaceState(
        {},
        null,
        "/search" + window.location.href.split("/search")[1] + "#results"
      );
      // }, 400);
    }

    this.toggleFilter("all");
  },
  methods: {
    sortByDate: function(ar) {
      var fi = ar.filter((el) => el.search_date !== "");
      return fi.sort(
        (a, b) => new Date(b.search_date) - new Date(a.search_date)
      );
    },
    scrollToResults: function() {
      window.setTimeout(() => {
        if (this.results !== null && this.results.length !== 0) {
          this.$scrollTo("#results", 500, {});
          if (!window.location.href.includes("#results")) {
            window.history.replaceState(
              {},
              null,
              "/search" + window.location.href.split("/search")[1] + "#results"
            );
          }
        }
      }, 400);
    },
    formatDate: function(string) {
      if (!string) return null;
      const d = new Date(string);
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
      return `${mo} ${da}, ${ye}`;
    },
    formatSubtype: function(subtype) {
      if (subtype === "post") {
        return "news";
      } else {
        return subtype;
      }
    },
    checkForActive: function(type) {
      type = this.formatSubtype(type);
      return this.activeType === type || this.activeType === null
        ? "active"
        : "inactive";
    },
    toggleFilter: function(type) {
      this.activeType = type;
      if (this.activeType === "all") this.activeType = null;
    },
    toggleSort: function(sortType) {
      this.activeSort = sortType;
    },
    cleanURL: function(url, type, title = "") {
      var pieces = url.split("/api");
      var typeSegment = type === "post" ? "/news" : "";
      if (title.includes("Stack")) {
        typeSegment = "/stack";
      }
      return window.location.origin + typeSegment + pieces[1];
    },
    checkForTypeResults: function(type) {
      type = this.formatSubtype(type);
      return (
        this.carlsonResults.some(
          (el) => this.determineSubtypeByURL(el.link) === type
        ) ||
        this.carlsonYoutubeResults.some(
          (el) => this.determineSubtypeByURL(el.link) === type
        ) ||
        this.carlsonCustomResults.some(
          (el) => this.determineSubtypeByURL(el.link) === type
        ) ||
        this.wpResults.some((el) => this.formatSubtype(el.type) === type)
      );
    },
    determineSubtypeByURL: function(url) {
      if (url.includes("knowledgebase")) {
        return "knowledgebase";
      }
      if (url.includes("manuals")) {
        return "manual";
      }
      if (url.includes(".pdf")) {
        return "brochure";
      }
      if (url.includes("forum")) {
        return "forum";
      }
      if (url.includes("stack")) {
        return "collection";
      }
      if (url.includes("product")) {
        return "product";
      }
      if (url.includes("news")) {
        return "news";
      }
      return "general";
    },
  },
  computed: {
    wpResults: function() {
      var set = this.results.filter((el) => !el["0"].hasOwnProperty("google"));
      return set[0] || [];
    },
    wpResultsNewsLast: function() {
      var set = this.results.filter((el) => !el["0"].hasOwnProperty("google"));
      var final = [];
      if (set.length) {
        final = set[0].sort(function(a, b) {
          if (a.subtype === "post" && b.subtype === "post") return 0;
          if (a.subtype !== "post" && b.subtype === "post") return -1;
          if (a.subtype === "post" && b.subtype !== "post") return 1;
          if (a.subtype !== "post" && b.subtype !== "post") return 0;
        });
      }
      return final;
    },
    carlsonResults: function() {
      var set = this.results.filter((el) => el["0"].hasOwnProperty("google"));
      return set.length && set[0].length ? set[0][0].google : [];
    },
    carlsonCustomResults: function() {
      var set = this.results.filter((el) => el["0"].hasOwnProperty("custom"));
      return set.length && [0].length ? set[0][0].custom : [];
    },
    carlsonYoutubeResults: function() {
      var set = this.results.filter((el) => el["0"].hasOwnProperty("youtube"));
      return set.length && [0].length ? set[0][0].youtube : [];
    },
    totalResultsCount: function() {
      return (
        this.carlsonResults.length +
        this.carlsonCustomResults.length +
        this.carlsonYoutubeResults.length +
        this.wpResults.length
      );
    },
  },
};
</script>

<style scoped lang="scss">
.SearchGrid {
  @extend .limit;
  @extend .cf;
  padding-top: 64px;
  padding-bottom: 96px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  .filters {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mobileOnly {
    display: none;
    @include med {
      display: block;
    }
  }

  .buttonLi {
    margin-top: 70px;
  }

  strong.date em {
    font-size: 11.5px;
    color: #888 !important;
    display: block;
    padding-top: 2px;
    padding-bottom: 6px;
  }

  .tag {
    margin-right: 12px;
    text-transform: uppercase;
    background: $navy;
    font-size: 11px;
    height: 18px;
    text-align: center;
    display: inline-block;
    color: white;
    padding: 2px 8px;
    position: relative;
    top: -2px;
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.25s;

    @include med {
      margin-left: 0 !important;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    &.noResults {
      display: none;
    }

    &.inactive {
      transition: opacity 0.25s;
      opacity: 0.4;
    }
  }

  h2 {
    padding-bottom: 14px;
  }

  h5 {
    font-weight: normal;
    padding-bottom: 10px;
    strong {
      font-style: normal;
      font-weight: 700;
    }
  }

  ul {
    padding-left: 0px;
    padding-top: 2px;
    list-style: none;

    a {
      text-decoration: none;
    }

    li {
      margin-top: 12px;
      list-style: none;

      li {
        border-bottom: 1px solid #ccc;
        padding-bottom: 28px;
        margin-bottom: 18px;

        &.inactive {
          display: none;
        }

        a em {
          font-size: 14.5px;
          color: $navy;
          display: inline-block;
          padding-bottom: 4px;
        }

        p {
          font-size: 16.5px;
          padding-top: 2px;
        }

        &:last-of-type {
          margin-bottom: 0 !important;
          padding-bottom: 28px;
        }
      }

      h3 {
        text-transform: none;
        padding-top: 3px;
        padding-bottom: 3px;
        color: $blue;

        .tag {
          margin-left: 12px;

          @include med {
            font-size: 8px;
            padding: 4px 7px 2px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
