<template>
  <div class="ProductNav">
    <h1>Carlson works where you work</h1>
    <div class="SelectWrap">
      <Multiselect
        v-model="value"
        :options="options"
        :searchable="true"
        :close-on-select="true"
        :show-labels="true"
        placeholder="Tell us what you do"
      ></Multiselect>
    </div>
    <ul>
      <li class="ProductNavItem">
        <div
          class="bg"
          :style="
            'background-image: url(' + require(`@/assets/img/civil.png`) + ')'
          "
        ></div>
        <h3>Civil Engineering</h3>
      </li>
      <li class="ProductNavItem">
        <div
          class="bg"
          :style="
            'background-image: url(' + require(`@/assets/img/mining.png`) + ')'
          "
        ></div>
        <h3>Mining</h3>
      </li>
      <li class="ProductNavItem">
        <div
          class="bg"
          :style="
            'background-image: url(' + require(`@/assets/img/land.png`) + ')'
          "
        ></div>
        <h3>Land Surveying</h3>
      </li>
      <li class="ProductNavItem">
        <div
          class="bg"
          :style="
            'background-image: url(' +
              require(`@/assets/img/construction.png`) +
              ')'
          "
        ></div>
        <h3>Construction</h3>
      </li>
    </ul>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "ProductNav",
  components: { Multiselect },
  data() {
    return {
      value: "",
      options: [
        "Civil Engineering",
        "Land Surveying",
        "Construction",
        "Mining",
        "Photogrammetry",
        "GIS",
        "CSI",
        "Agriculture",
        "Marine",
        "Landfill",
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.ProductNav {
  @extend .limit;

  h1 {
    letter-spacing: 0.06125em;
    color: $navy;
    padding-top: 32px;
    padding-bottom: 24px;
    text-align: center;
  }

  ul {
    @extend .cf;
    margin-bottom: 32px;

    .ProductNavItem {
      @extend .destruct;
      width: calc(25% - 12px);
      float: left;
      list-style: none;
      height: 200px;
      position: relative;
      transition: all 0.4s;
      cursor: pointer;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-left: 16px;
      @include smx {
        margin-bottom: 16px !important;
      }

      &:first-of-type {
        margin-left: 0;
      }

      .bg {
        content: "";
        opacity: 0.35;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        transition: all 0.4s;
        transform: scale(1);
      }

      h3 {
        position: absolute;
        bottom: 0;
        right: 0;
        color: $navy;
        transition: all 0.4s;
        @extend .got;
        text-transform: none;
        font-weight: 900;
        padding: 8px 12px;
        font-size: 24px;
      }

      &:hover {
        transition: all 0.4s;
        opacity: 1;

        h3 {
          color: $light;
          transition: all 0.8s;
          text-shadow: 3px 3px 3px $navy;
        }

        .bg {
          opacity: 1;
          transition: all 0.4s;
          transform: scale(1.04);
        }
      }
    }
  }

  .SelectWrap {
    padding-top: 16px;
    padding-bottom: 96px;
    position: relative;
    z-index: 50;
    @include smx {
      padding-bottom: 32px;
    }

    .multiselect {
      position: absolute;
      left: 24.35%;
      width: 50%;
      transform: scale(1.333);
      @include smx {
        width: 100%;
        position: static;
        transform: scale(1);
      }
    }
  }
}
</style>
