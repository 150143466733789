<template>
  <div id="app" class="appV">
    <router-view :key="$route.fullPath" />
    <modal
      name="locateModal"
      :scrollable="false"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      transition="fade"
    >
      <button class="modalCloseButton" @click="$modal.hide('locateModal')">X</button>
      <iframe
        name="googlemapsinnerframe"
        src="https://web.carlsonsw.com/files/reseller05/"
        frameborder="0"
        width="100%"
        height="700"
      ></iframe>
    </modal>
    <modal
      name="quoteModal"
      :scrollable="false"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      transition="fade"
    >
      <button class="modalCloseButton" @click="$modal.hide('quoteModal')">X</button>
      <iframe
        name="quoteforminnerframe"
        src="/snippets/iframe.html"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </modal>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    shouldStop: function() {
      return true;
    }
  },
  methods: {
    beforeOpen(event) {
      document.body.classList.add("stopScrolling");
    },
    beforeClose(event) {
      document.body.classList.remove("stopScrolling");
    }
  },
  mounted() {
    if (window.location.href.includes('#')) {
      var id = window.location.href.split('#')[1]
      window.setTimeout(() => {
        this.$scrollTo("#"+id, 100, {})
      }, 1200)
      }
  }
};
</script>

<style lang="scss">
html,
body {
  background: #313131 !important;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .v--modal-overlay {
    z-index: 99997;
  }

  .v--modal-overlay {
    z-index: 99998;
    background: rgba(0, 0, 0, 0.75) !important;
  }

  .v--modal-box {
    z-index: 99999;
    width: 80% !important;
    left: 10% !important;
    height: 80vh !important;
    top: 10vh !important;
    @include smx {
      width: 100vw !important;
      left: auto !important;
    }
  }
}

body.stopScrolling {
  height: 100%;
  overflow: hidden;
}

.modalCloseButton {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  background: #313131;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 100%;
  font-size: 16px;
  transition: all 0.5s;
  cursor: pointer;
  border: 2px solid white;

  &:hover {
    transition: all 0.5s;
    border: 2px solid #ffaaaa;
    color: #ffaaaa;
  }
}
</style>
