<template>
  <div class="BigInput">
    <h2>{{ title }}</h2>
    <form>
      <input v-model="bigInputValue" type="text" :placeholder="placeholder" />
      <button @click.prevent="emitValue">
        <i :class="`fa fa-${icon}`"></i>{{ buttonLabel }}
      </button>
    </form>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule.vue";

export default {
  name: "BigInput",
  components: { Rule },
  props: {
    title: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    inputValue: {
      type: String,
    },
    icon: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
  },
  data: function() {
    return {
      bigInputValue: this.inputValue,
    };
  },
  methods: {
    emitValue: function() {
      this.$emit("updateSearchTermValue", this.bigInputValue);
    },
  },
};
</script>

<style scoped lang="scss">
.BigInput {
  h2 {
    color: $blue;
    padding-top: 32px;
    padding-bottom: 8px;
    max-width: 55%;
    @extend .destruct;
    font-size: 24px;
  }

  input[type="text"] {
    font-family: inherit;
    margin-top: 2px;
    font-size: 16px;
    padding: 8px 8px 5px 12px;
    width: 240px;
    border-radius: 0;
    border: none;
    outline: none;
    border: 1px solid #999;

    &::placeholder {
      font-style: italic;
      color: #aaa;
    }
  }

  button {
    background: #979797;
    color: white;
    border: none;
    outline: none;
    padding: 9px 20px 6px 7px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    -webkit-appearance: none;
    transition: all 0.25s;
    cursor: pointer;

    i {
      font-size: 14px;
      margin-right: 1px;
      padding: 4px 8px;
    }

    &:hover {
      background: $blue;
      transition: all 0.25s;
    }
  }
}
</style>
