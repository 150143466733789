<template>
  <div class="Lede">
    <h1>{{ title }}</h1>
    <p>
      <slot></slot>
    </p>
    <Rule thirds></Rule>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";

export default {
  name: "Lede",
  components: { Rule },
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.Lede {
  background: white;
  @extend .cf;
  @extend .limit;
  @extend .destruct;

  color: $blue;
  font-size: 16px;
  letter-spacing: 0.0236125em;
  padding-top: 72px;
  padding-bottom: 36px;
  @include smx {
    font-size: 18px;
  }

  .Rule {
    padding-top: 32px;
  }

  h1 {
    font-size: 24px;
  }

  p {
    color: #333;
    padding-top: 16px;
    line-height: 1.75em;
  }
}
</style>
