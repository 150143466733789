var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Button",class:{
    inverse: _vm.inverse,
    half: _vm.half,
    quarter: _vm.quarter,
    third: _vm.third,
    auto: _vm.auto,
    thirds: _vm.thirds,
    center: _vm.center,
    pb: _vm.pb,
  }},[(_vm.href && _vm.newTab)?_c('a',{attrs:{"href":_vm.href,"target":"_blank"}},[_vm._v(_vm._s(_vm.text))]):(_vm.href)?_c('a',{attrs:{"href":_vm.href}},[_vm._v(_vm._s(_vm.text))]):(_vm.clickFunction)?_c('a',{on:{"click":function($event){_vm.clickFunction() || ''}}},[_vm._v(_vm._s(_vm.text))]):_c('span',[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }