<template>
  <div class="topbar">
    <div class="limit">
      <div class="left"></div>
      <div class="right">
        <div class="wrap">
          <ul class="lang">
            <li class="active">
              <a href="https://account.carlsonsw.com/">
                Sign In
                <span class="sign-in-icon"></span>
              </a>
            </li>
            <li class="active globalLink">
              <a href="/about-us/corporate-websites">
                Carlson Global
                <img class="globe" src="@/assets/img/globe.svg" />
              </a>
            </li>
            <li class="active siteSearch">
              <form>
                <input
                  v-model="topBarSearchTerm"
                  type="text"
                  placeholder="Search"
                />
                <button @click.prevent="goToSearchWithTerm">
                  <i class="fa fa-search"></i>
                </button>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TopBar",
  components: {},
  data() {
    return {
      topBarSearchTerm: null,
    };
  },
  methods: {
    goToSearchWithTerm: function() {
      this.$router.push({
        name: "Search",
        hash: "#results",
        query: { q: this.topBarSearchTerm },
        params: { searchTerm: this.topBarSearchTerm },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  background: #555;
  background: #444;
  color: white;
  font-size: 12px;
  height: 29px;
  @include smx {
    display: none;
  }

  .limit {
    @extend .cf;
    padding-top: 0;
    padding-bottom: 0;
  }

  .left,
  .right {
    width: 50%;
    float: left;
    min-height: 1px;
    text-align: right;
    @extend .destruct;
  }

  .wrap {
    display: inline-block;
    height: 16px;
    position: relative;
    width: 560px;

    ul {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      li {
        list-style: none;
        display: inline-block;
        background: $grey;
        padding: 2px 16px 2px;
        text-align: left;
        margin-right: 2px;
        cursor: pointer;
        a {
          text-decoration: none;
        }

        &:hover {
          background: #666;
        }

        &.active {
          display: inline-block;
          font-weight: 800;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      span {
        position: relative;
        top: -2px;
        &.sign-in-icon {
          @include arrow-right(4px, white);
        }
      }
    }
  }

  .globalLink {
    a {
      &:hover {
        opacity: 1;
        transition: all 0.4s;

        .globe {
          opacity: 1;
          display: none;
        }
      }

      .globe {
        display: inline-block;
        box-sizing: content-box;
        height: 10px;
        width: 10px;
        vertical-align: middle;
        padding-left: 4px;
        position: relative;
        top: -1.5px;
        transition: all 0.4s;
        display: none;
      }
    }
  }

  input[type="text"] {
    font-family: inherit;
    margin-top: 2px;
    font-size: 12px;
    padding: 2px 8px 2px;
    width: 180px;
    border-radius: 0;
    border: none;
    outline: none;
  }

  .siteSearch {
    display: inline-block;
    background: transparent !important;
    padding-left: 0 !important;

    button {
      background: #979797;
      color: white;
      border: none;
      outline: none;
      padding: 2px 7px 3px;
      -webkit-appearance: none;

      i {
        font-size: 10px;
      }
    }
  }
}
</style>
