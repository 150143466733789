<template>
  <div class="NewsTemplate">
    <Header></Header>
    <div class="mainWrap">
      <Main v-if="apiResult" id="intro">
        <div class="PostHeader">
          <div>
            <div>
              <h2 v-html="apiResult.title.rendered"></h2>
              <h5 class="PostMeta">{{ apiResult.date|date('%A %B %-d, %Y') }}</h5>
            </div>
            <img
              class="PostImage"
              v-if="apiResult.featured_img_url"
              :src="apiResult.featured_img_url"
            />
          </div>
        </div>
        <div class="PostContent cms" v-html="apiResult.content.rendered"></div>
        <SeeMore>
          <Button>
            <router-link :to="'/news'">See More News From Carlson</router-link>
          </Button>
        </SeeMore>
        <LocateDealer v-scroll-reveal="{ opacity: 0 }" id="dealer" />
        <!-- <Testimonials :fields="apiResult.acf.testimonial" id="testimonials" /> -->
      </Main>
    </div>
    <Footer noImage id="footer" />
  </div>
</template>

<script>
import Button from "@/atoms/Button";
import Rule from "@/atoms/Rule";

import LocateDealer from "@/molecules/LocateDealer";
import PurchaseBlock from "@/molecules/PurchaseBlock";
import YoutubeVideo from "@/molecules/YoutubeVideo";
import TwoUpStatic from "@/molecules/TwoUpStatic";
import TwoUp from "@/molecules/TwoUp";
import PageSubNav from "@/molecules/PageSubNav";
import FeatureBlocks from "@/molecules/FeatureBlocks";
import Hero from "@/molecules/Hero";
import SimpleHero from "@/molecules/SimpleHero";

import Header from "@/components/Header";
import Main from "@/components/Main";
import Footer from "@/components/Footer";
import ProductNav from "@/components/ProductNav";
import Testimonials from "@/components/Testimonials";
import ResourcesBar from "@/components/ResourcesBar";
import SeeMore from "@/components/SeeMore";

import axios from "axios";

export default {
  name: "NewsTemplate",
  components: {
    Header,
    Hero,
    SimpleHero,
    SeeMore,
    ResourcesBar,
    PurchaseBlock,
    LocateDealer,
    YoutubeVideo,
    Button,
    Rule,
    PageSubNav,
    FeatureBlocks,
    TwoUp,
    TwoUpStatic,
    Main,
    Footer,
    ProductNav,
    Testimonials,
  },
  data() {
    return {
      apiResult: null,
    };
  },
  computed: {
    slug: function () {
      return this.$route.params.newsSlug;
    },
  },
  mounted: function () {
    axios
      .get(window.API + "/wp/v2/posts?embed&slug=" + this.slug)
      .then((response) => (this.apiResult = response.data[0]))
      .catch((error) => console.log(error));
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.NewsTemplate {
  .PostHeader {
    background: $midnight;
    padding: 16px 0 24px;
    overflow: hidden;

    > div {
      @extend .limit;
      @extend .destruct;

      > div {
        width: 50%;
        float: left;
        @include med {
          float: none;
          width: auto;
        }
      }
    }

    h2 {
      color: white;
      text-transform: none;
    }

    .PostMeta {
      text-transform: none !important;
      color: $blue;
      padding-top: 8px;
    }

    .PostImage {
      float: right;
      width: auto;
      max-height: 180px;
      @include med {
        margin-top: 32px;
      }
    }
  }

  .PostContent {
    padding-top: 32px;
    padding-bottom: 64px;
    @extend .limit;
    @extend .destruct;
  }

  .mainWrap {
    min-height: 500px;
    background: #313131;
  }
}
</style>
