import Vue from "vue";
import VueFilter from "vue-filter";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "./mixins";
import Multiselect from "vue-multiselect";
import VueScrollTo from "vue-scrollto";
import VueScrollReveal from "vue-scroll-reveal";
import VModal from "vue-js-modal";

var envLocal = window.location.href.includes("localhost");
window.CarlsonSearchAPI = "https://update.carlsonsw.com/search/?";

if (envLocal) {
  // window.API = "https://www.carlsonsw.com/api/index.php/wp-json";  
  window.API = "https://wdev.carlsonsw.com/api/index.php/wp-json";
} else {
  if (window.location.href.includes("www05.carlsonsw")) {
    window.API = "https://www05.carlsonsw.com/api/index.php/wp-json";
  } else if (window.location.href.includes("wdev.carlsonsw")) {
    window.API = "https://wdev.carlsonsw.com/api/index.php/wp-json";
  } else if (window.location.href.includes("jdselig.com")) {
    window.API = "https://www05.carlsonsw.com/api/index.php/wp-json";
  } else {
    window.API = window.location.origin + "/api/index.php/wp-json";
  }
}

window.WPSearchAPI = window.API + `/wp/v2/search?search=`;

Vue.component("multiselect", Multiselect);
Vue.config.productionTip = false;

Vue.mixin(mixins);
Vue.use(VueFilter);
Vue.use(VModal);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -270,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal-x",
  viewOffset: {
    top: -50,
  },
  delay: 0,
  useDelay: false,
  distance: "40px",
  duration: 500,
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  interval: 0,
  opacity: 0,
  origin: "left",
  rotate: {
    x: 0,
    y: 0,
    z: 0,
  },
  scale: 1,
  cleanup: false,
  container: document.documentElement,
  desktop: true,
  mobile: false,
  reset: false,
  viewFactor: 0.0,
  beforeReveal: function(el) {},
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
