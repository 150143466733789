<template>
  <div class="NewsTemplate">
    <Header></Header>
    <div class="mainWrap">
      <Main>
        <News />
      </Main>
    </div>
    <Footer noImage id="footer" />
  </div>
</template>

<script>
import Button from "@/atoms/Button";
import Rule from "@/atoms/Rule";

import LocateDealer from "@/molecules/LocateDealer";
import PurchaseBlock from "@/molecules/PurchaseBlock";
import YoutubeVideo from "@/molecules/YoutubeVideo";
import TwoUpStatic from "@/molecules/TwoUpStatic";
import TwoUp from "@/molecules/TwoUp";
import PageSubNav from "@/molecules/PageSubNav";
import FeatureBlocks from "@/molecules/FeatureBlocks";
import Hero from "@/molecules/Hero";
import SimpleHero from "@/molecules/SimpleHero";

import Header from "@/components/Header";
import Main from "@/components/Main";
import Footer from "@/components/Footer";
import ProductNav from "@/components/ProductNav";
import Testimonials from "@/components/Testimonials";
import ResourcesBar from "@/components/ResourcesBar";

import News from "@/components/News";

import axios from "axios";

export default {
  name: "NewsTemplate",
  components: {
    Header,
    Hero,
    SimpleHero,
    ResourcesBar,
    PurchaseBlock,
    LocateDealer,
    YoutubeVideo,
    Button,
    Rule,
    PageSubNav,
    FeatureBlocks,
    TwoUp,
    TwoUpStatic,
    Main,
    Footer,
    ProductNav,
    Testimonials,
    News
  },
  data() {
    return {
      apiResult: null
    };
  },
  computed: {
    slug: function() {
      return this.$route.params.newsSlug;
    }
  },
  mounted: function() {
    axios
      .get(window.API + "/wp/v2/posts?embed&slug=" + this.slug)
      .then(response => (this.apiResult = response.data[0]))
      .catch(error => console.log(error));
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.NewsTemplate {
  .PostHeader {
    background: $midnight;
    padding: 24px 0;

    h1 {
      color: white;
      text-transform: none;
      @extend .limit;
      @extend .destruct;
    }

    .PostMeta {
      text-transform: none !important;
      color: $blue;
      @extend .limit;
      @extend .destruct;
      padding-top: 8px;
    }
  }

  .PostContent {
    padding-top: 48px;
  }

  .mainWrap {
    min-height: 500px;
    background: #313131;
  }
}
</style>
