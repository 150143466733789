<template>
  <div class="FeatureBlocks">
    <div
      class="FeatureBlock"
      v-for="(block, index) in blocks"
      :key="'block-' + index"
      :id="block.anchor ? block.anchor.replace('#', '') : ''"
    >
      <div class="FeatureBlockWrap cms" :class="{ noImage: !block.feature_block_image }">
        <h2 class="featureBlockTitle">{{ block.feature_block_heading }}</h2>
        <div v-if="block.feature_block_image" class="imgWrap" v-scroll-reveal="{ opacity: 0 }">
          <img :src="block.feature_block_image" />
        </div>
        <div class="content" v-scroll-reveal="{ opacity: 0 }">
          <p v-html="block.feature_block_content"></p>
        </div>
      </div>
    </div>
    <modal
      name="imageModal"
      :scrollable="false"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      transition="fade"
    >
      <button class="modalCloseButton" @click="$modal.hide('imageModal')">X</button>
      <img style="display:block;width:100%;" :src="imgSrc" />
    </modal>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";
import Button from "@/atoms/Button";
import ImageSpinner from "@/molecules/ImageSpinner";

export default {
  name: "FeatureBlocks",
  components: { Rule, Button, ImageSpinner },
  data() {
    return {
      imgSrc: null,
    };
  },
  methods: {
    show(src) {
      this.imgSrc = src;
      this.$modal.show("imageModal");
    },
    hide() {
      this.imgSrc = null;
      this.$modal.hide("imageModal");
    },
    beforeOpen(event) {
      document.body.classList.add("stopScrolling");
    },
    beforeClose(event) {
      document.body.classList.remove("stopScrolling");
    },
  },
  // mounted() {
  //   console.log(this._props.blocks)
  // },
  props: {
    blocks: {
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
.FeatureBlocks {
  ul,
  li {
    list-style: auto !important;
  }

  .FeatureBlockWrap {
    @extend .cf;
    @extend .limit;
    @extend .destruct;
    padding-bottom: 72px;
    @include smx {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    &.noImage {
      .imgWrap {
        display: none !important;
      }
      .content {
        float: none !important ;
        width: 100%;

        p {
          margin-left: 0;
        }
      }
    }
  }

  .FeatureBlock {
    overflow: hidden;
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 24px;
    background: #dfdfdf;

    @include smx {
      padding-left: 20px;
      padding-right: 20px;
    }

    h2.featureBlockTitle {
      color: #208fcc;
      line-height: 1.55em;
    }

    .imgWrap {
      width: 33%;
      float: left;
      @include smx {
        width: 100%;
        float: none;
      }

      img {
        display: block;
        // cursor: pointer;
        width: auto;
        max-width: 100%;
        margin: 32px auto 16px;
        float: left;
        @include smx {
          width: 100%;
          float: none;
          margin: 8px auto 8px;
        }
      }
    }

    ul,
    li {
      list-style: auto !important;
    }

    .content {
      width: 50%;
      padding-top: 16px;
      float: right;
      @include smx {
        width: 100%;
        float: none;
      }

      ul {
        margin-left: 24px !important;
      }

      p {
        font-size: 18px;
        line-height: 36px;

        /deep/ ul {
          margin-bottom: 24px;
        }

        /deep/ li {
          margin-left: 24px;
        }

        ul,
        li {
          list-style: auto !important;
        }
      }
    }

    &:nth-of-type(odd) {
      background: #fff;
      .imgWrap {
        float: right;
        @include smx {
          width: 100%;
          float: none;
        }

        img {
          float: right;
        }
      }
      .content {
        float: left;
        @include smx {
          width: 100%;
          float: none;
        }
      }
    }
  }
}
</style>
