<template>
  <div class="Intro">
    <h2>{{ title }}</h2>
    <p><slot></slot></p>
    <Rule half />
  </div>
</template>

<script>
import Rule from "@/atoms/Rule.vue";

export default {
  name: "Intro",
  components: { Rule },
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.Intro {
  @extend .limit;
  @extend .destruct;

  h2 {
    color: $blue;
    padding-top: 32px;
    padding-bottom: 8px;
    max-width: 55%;
    @extend .destruct;
    font-size: 24px;
  }

  p {
    max-width: 55%;
    line-height: 32px;
    padding-bottom: 16px;
    @extend .destruct;
  }
}
</style>
