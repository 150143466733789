var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"ResourcesBar",attrs:{"id":"Resources"}},[_c('h5',[_vm._v("Resources")]),_c('ul',[_vm._l((_vm.links),function(link,index){return [_c('li',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
          delay: 500 + 160 * index,
          distance: '0px',
          origin: 'left',
        }),expression:"{\n          delay: 500 + 160 * index,\n          distance: '0px',\n          origin: 'left',\n        }"}],key:'resource-' + index,class:{ hidden: _vm.hidden.includes(link.title) }},[(_vm.overrides && _vm.overrides[link.slug + '_link_override'] != '')?_c('a',{attrs:{"href":_vm.overrides[link.slug + '_link_override']}},[_c('img',{attrs:{"src":require(("@/assets/" + (link.image))),"alt":link.title}}),_c('span',[_vm._v(_vm._s(link.name))])]):(link.target != null && link.target != '')?_c('a',{attrs:{"href":_vm.linkBase + _vm.slug + link.href,"target":link.target}},[_c('img',{attrs:{"src":require(("@/assets/" + (link.image))),"alt":link.title}}),_c('span',[_vm._v(_vm._s(link.name))])]):((_vm.slug !== null) & (_vm.slug !== ''))?_c('a',{attrs:{"href":_vm.linkBase + _vm.slug + link.href},on:{"click":function($event){$event.preventDefault();_vm.modalLink(
              _vm.linkBase +
                (_vm.theTags.length > 0 ? _vm.TAG_INDEXES[_vm.theTags[0]] : 'noTag') +
                link.href
            )}}},[_c('img',{attrs:{"src":require(("@/assets/" + (link.image))),"alt":link.title}}),_c('span',[_vm._v(_vm._s(link.name))])]):_c('a',{attrs:{"href":_vm.linkBase + link.href.replace('+', '')}},[_c('img',{attrs:{"src":require(("@/assets/" + (link.image))),"alt":link.title}}),_c('span',[_vm._v(_vm._s(link.name))])])])]})],2),_c('modal',{attrs:{"name":"resourceModal","scrollable":false,"transition":"fade"},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('button',{staticClass:"modalPopOutButton",on:{"click":function($event){return _vm.popout('resourceModal')}}},[_c('i',{staticClass:"fa fa-arrow-up",staticStyle:{"transform":"rotate(45deg)"}})]),_c('button',{staticClass:"modalCloseButton",on:{"click":function($event){return _vm.$modal.hide('resourceModal')}}},[_vm._v("X")]),(_vm.modalContent && _vm.modalContentSrc)?_c('div',[_c('iframe',{attrs:{"src":_vm.modalContentSrc}})]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }