<template>
  <li class="ProductPreviewGridItem">
    <router-link class="gridLink" :to="'/product/' + product.ttSlug">
      <section>
        <img :src="product.ttImage" />
        <h4>{{ product.post_title }}</h4>        
        <div class="tx">
        <div          
          v-if="product.ttFields && product.ttFields.product_tagline"
          v-html="product.ttFields.product_tagline"
        ></div>
        <div v-else-if="product.ttBlurb">{{ product.ttBlurb }}</div>
        <div v-else>More information coming soon!</div>
        <br />
        <InternalLink
          title="Learn More >"
          :href="'/product/' + product.ttSlug"
        ></InternalLink>
        </div>
      </section>
    </router-link>
  </li>
</template>

<script>
import Rule from "@/atoms/Rule.vue";
import InternalLink from "@/atoms/InternalLink.vue";

export default {
  name: "ProductPreviewGridItem",
  components: { Rule, InternalLink },
  props: {
    product: {
      type: Object,
    },
  },
  data: function() {
    return {
      currentProducts: [],
    };
  },
};
</script>

<style scoped lang="scss">
.ProductPreviewGridItem {
  @extend .limit;
  @extend .destruct;
  padding: 8px 0 8px 8px;
  list-style: none;
  cursor: pointer;
  display: block;
  // height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  margin-bottom: 40px;
  width: 31.333%;
  box-sizing: border-box;
  margin-right: 2%;
  float: left;
  position: relative;
 
 &:not(:nth-child(3n + 1)) {border-right: 1px solid rgba(0, 10, 100, 0.15);}

  @include med {
    padding-left: 0px !important;
    &:after {
      content: "";
      width: 90%;
      border-top: 1px solid #eee;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 20px;
      left: 0;
    }
  }

  @include smx {
    margin-bottom: 40px !important;

  }

  .gridLink {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: contain;
    display: block;
    margin-right: 4%;
    min-height: 1px;

    @include med {
      height: auto;
      width: 50%;
    }

    @include smx {
      height: auto;
      width: 80%;
    }
  }

  section {
    float: left;
    width: 100%;

    > div {
      font-size: 14px;
      padding-right: 8%;
    }
  }
  
  .tx{
    height: 100px;
  }

  h4 {
    color: $navy;
    padding-top: 16px;
    padding-bottom: 16px;
    @extend .destruct;
    text-transform: none;
    font-size: 18px;

    @include med {
      padding-left: 0 !important;
    }
  }

  p {
    max-width: 55%;
    line-height: 32px;
    padding-bottom: 16px;
    @extend .destruct;
  }

  .active {
    font-weight: bold;
  }

  h6 {
    display: inline-block;
    padding-bottom: 12px;
  }
}
</style>
