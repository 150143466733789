<template>
  <div class="ProductPage">
    <Header>
      <ProductSubNav
        v-if="apiResult"
        :title="apiResult.title.rendered"
        :tag="getTagSlug(apiResult.tags[0])"
        :demoLinkOverride="apiResult.acf.link_overrides ? apiResult.acf.link_overrides.demo_link_override : ''"
      />
      <Hero
        v-if="apiResult && apiResult.acf.product_hero_video"
        :slides="[{hero_slide_movie: apiResult.acf.product_hero_video, hero_slide_title: apiResult.title.rendered, hero_slide_text: apiResult.acf.product_tagline }]"
      ></Hero>
      <SimpleHero
        v-else-if="apiResult && apiResult.acf.product_hero_image"
        v-scroll-reveal="{ opacity: 0 }"
        :src="apiResult.acf.product_hero_image"
        :title="apiResult.title.rendered"
      >
        <span v-html="apiResult.acf.product_tagline"></span>
      </SimpleHero>
    </Header>
    <div class="mainWrap">
    <AdBlock v-if="acfOptions && (acfOptions.adblock_locations == 'products' || acfOptions.adblock_locations == 'both')"></AdBlock>
      <Main v-if="apiResult">
        <TwoUpStatic
          :backgroundColor="COLORS.$lightestGrey"
          :src="apiResult.featured_img_url || ''"
          title="Overview"
          includeButton
          :buttonText="apiResult.acf.product_intro_button_text"
          :buttonLink="apiResult.acf.product_intro_button_url"
        >
          <div
            id="Overview"
            v-if="apiResult.acf.product_intro"
            v-html="apiResult.acf.product_intro"
          ></div>
        </TwoUpStatic>
        <!-- <TwoUp
        title="Product Features"
        :items="apiResult.acf.product_features"
        :singleImage="apiResult.acf.product_detail_image || null"
        layoutReverse
        />-->
        <!-- <ProductFeaturesBlock
        title="Product Features"
        :items="apiResult.acf.product_features"
        :singleImage="apiResult.acf.product_detail_image || null"
        layoutReverse
        />-->
        <div class="YoutubeVideoWrap cf" v-scroll-reveal="{ opacity: 0 }">
          <YoutubeVideo half :src="apiResult.acf.product_video" />
        </div>
         <ResourcesBar
          :theTags="apiResult.tags"
          :hidden="apiResult.acf.hidden_resources || []"
          :overrides="apiResult.acf.link_overrides ? apiResult.acf.link_overrides.resources_link_overrides : null"
          :slug="slug"
        />
        <FeatureBlocks
          v-if="apiResult.acf && apiResult.acf.feature_blocks"
          :blocks="apiResult.acf.feature_blocks"
        />
        <!-- <TwoUp
        v-if="apiResult.acf.product_feature_block"
        :imageSet="apiResult.acf.product_feature_block.map(block => { return block.image })"
        :items="apiResult.acf.product_tech_specs"
        :backgroundColor="COLORS.$lightestGrey"
        />-->
       
        <LocateDealer v-scroll-reveal="{ opacity: 0 }" />
        <PurchaseBlock
          v-if="apiResult.acf && apiResult.acf.purchase_fields"
          :title="apiResult.title.rendered"
          :fields="apiResult.acf.purchase_fields"
          :overrides="apiResult.acf.link_overrides"
          :tag="getTagSlug(apiResult.tags[0])"
        />
        <SeeMore>
          <h5>Also from Carlson</h5>
          <Button
            v-for="(tag, index) in apiResult.tags"
            :key="'tag-' + index"
            :text="'See More Products in '+ TAG_INDEXES[tag]"
            :href="'/stack/' + TAG_INDEXES[tag]"
            center
            auto
            pb
          />
          <Button
            v-if="apiResult.tags.length == 0"
            key="tag-0"
            text="See More Products"
            :href="'/industry/'"
            center
            auto
            pb
          />
        </SeeMore>
        <Testimonials :fields="apiResult.acf.testimonial" />
      </Main>
    </div>
    <Footer noImage />
  </div>
</template>

<script>
import Button from "@/atoms/Button";
import Rule from "@/atoms/Rule";

import LocateDealer from "@/molecules/LocateDealer";
import PurchaseBlock from "@/molecules/PurchaseBlock";
import YoutubeVideo from "@/molecules/YoutubeVideo";
import TwoUpStatic from "@/molecules/TwoUpStatic";
import TwoUp from "@/molecules/TwoUp";
import ProductFeaturesBlock from "@/molecules/ProductFeaturesBlock";
import ProductSubNav from "@/molecules/ProductSubNav";
import FeatureBlocks from "@/molecules/FeatureBlocks";
import Hero from "@/molecules/Hero";
import SimpleHero from "@/molecules/SimpleHero";

import Header from "@/components/Header";
import AdBlock from "@/components/AdBlock";
import Main from "@/components/Main";
import Footer from "@/components/Footer";
import ProductNav from "@/components/ProductNav";
import Testimonials from "@/components/Testimonials";
import ResourcesBar from "@/components/ResourcesBar";
import SeeMore from "@/components/SeeMore";

import axios from "axios";

export default {
  name: "ProductPage",
  components: {
    AdBlock,
    Header,
    Hero,
    SimpleHero,
    ProductSubNav,
    SeeMore,
    ResourcesBar,
    PurchaseBlock,
    LocateDealer,
    YoutubeVideo,
    Button,
    Rule,
    ProductFeaturesBlock,
    FeatureBlocks,
    TwoUp,
    TwoUpStatic,
    Main,
    Footer,
    ProductNav,
    Testimonials
  },
  data() {
    return {
      apiResult: null
    };
  },
  computed: {
    slug: function() {
      return this.$route.params.productSlug;
    }
  },
  mounted: function() {
    axios
      .get(window.API + "/wp/v2/product?embed&slug=" + this.slug)
      .then(response => (this.apiResult = response.data[0]))
      .catch(error => console.log(error));

    axios.get(this.OPTIONS_ROUTE)
      .then((response) => {(this.acfOptions = response.data.acf)
      })
      .catch((error) => console.log(error)); 
  },
  methods: {
    getTagSlug: function(tagIndex) {
      if (typeof this.TAG_INDEXES[tagIndex] !== 'undefined') {
        var slug = this.TAG_INDEXES[tagIndex];
        return slug.charAt(0).toUpperCase() + slug.slice(1);
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ProductPage {
  .YoutubeVideoWrap {
    background: #212121;

    @include xlo {
      // padding-top: 32px;
      padding-left: 128px;
      padding-right: 128px;
    }
  }
  .mainWrap {
    min-height: 500px;
    background: #313131;
  }
}
</style>
