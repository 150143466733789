<template>
  <div class="Search">
    <Header>
      <template>
        <SimpleHero :src="null" title="Search Carlson" :showOnMobile="true">
          <span>Let's Find What You're Looking For</span>
          <BigInput
            placeholder="Search terms"
            icon="search"
            :inputValue="searchTerm"
            buttonLabel="Go"
            @updateSearchTermValue="updateParentWrap"
          />
        </SimpleHero>
      </template>
    </Header>
    <div class="mainWrap">
      <Main id="intro" :class="'searchMain'">
        <SearchGrid :term="searchTerm" :results="results" ref="grid" />
      </Main>
    </div>
    <Footer noImage id="footer" />
  </div>
</template>

<script>
import Button from "@/atoms/Button";
import SimpleHero from "@/molecules/SimpleHero";
import Header from "@/components/Header";
import Main from "@/components/Main";
import BigInput from "@/components/BigInput";
import Footer from "@/components/Footer";

import SearchGrid from "@/components/SearchGrid.vue";

import axios from "axios";

import testWPData from "@/data/search-survce-test.js";
import testCarlsonData from "@/data/search-survce-test-carlson.js";

export default {
  name: "Search",
  components: {
    Button,
    SimpleHero,
    Header,
    Main,
    BigInput,
    Footer,
    SearchGrid,
  },
  data() {
    return {
      apiResult: null,
      searchTerm: null,
      results: [],
    };
  },
  computed: {
    slug: function() {
      const params = this.$route.params;
      // return this.$route.params.pageSlug;
      return params.childSlug ? params.childSlug : params.pageSlug;
    },
  },
  mounted: function() {
    const params = this.$route.params;
    if (params.searchTerm) {
      this.updateParent(params.searchTerm);
    }

    if (this.$route.query.q) {
      this.updateParent(this.$route.query.q);
    }
  },
  methods: {
    updateParentWrap: function(data) {
      this.updateParent(data);
      this.$refs.grid.scrollToResults();
    },
    updateParent: function(data) {
      this.searchTerm = data;
      window.history.replaceState(
        {},
        null,
        this.$route.path + "?q=" + this.searchTerm
      );
      this.fetchResults();
    },
    fetchResults: function() {
      this.results = [];
      this.$refs.grid.toggleFilter("all");

      axios
        .get(window.WPSearchAPI + this.searchTerm)
        .then((response) => this.results.push(response.data))
        .catch((error) => console.log(error));

      axios
        .get(window.CarlsonSearchAPI + this.searchTerm)
        .then((response) => {
          this.results.push([response.data]);
        })
        .catch((error) => console.log(error));

      // this.results = testWPData;
      // this.results = [...this.results, testWPData];
    },
  },
};
</script>

<style scoped lang="scss">
.PageTemplate {
  .mainWrap {
    min-height: 500px;
    background: #313131;
  }
}
</style>
