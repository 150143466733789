<template>
  <div class="Stack">
    <Header>
      <Hero v-if="ob && ob.acf && ob.acf.hero_slides" :slides="ob.acf.hero_slides"></Hero>
      <StackSubNav
        :links="useProductFamilies ? PRODUCT_FAMILIES : INDUSTRIES"
        :inactiveSubLinks="inactiveSubLinks"
      />
    </Header>
    <!-- If the given 'industry' is NOT office-software, instruments, machine-control or data-collection (aka is NOT a product family)-->
    <Main v-if="useProductFamilies">
      <QuickLinks></QuickLinks>
      <Lede :title="'Carlson for ' + industryTitle"></Lede>
      <Intro v-if="ob" v-html="ob.content.rendered"></Intro>
      
      <!-- Since we were given an industry, we need to loop through all product families -->
      
      <ListOfProductsWithTag
        v-for="(ind, index) in PRODUCT_FAMILIES"
        :key="ind + '-list-' + index"
        :id="toPascalCase(ind)"
        :title="industryTitle.toUpperCase()"
        :title2="toTitleCase(ind)"
        :tagIds="[TAGS[industry], TAGS[ind]]"
        :inactiveSubLinks="inactiveSubLinks"
      ></ListOfProductsWithTag>
    </Main>
    <!-- If the given 'industry IS one of the product families, like office-software, then we want to show all industries -->
    <Main v-else>
      <QuickLinks></QuickLinks>
      <Lede :title="'Carlson for ' + industryTitle"></Lede>
      <Intro v-if="ob" v-html="ob.content.rendered"></Intro>
      <!-- Since we were given a product family, we need to loop through all industries -->
      <ListOfProductsWithTag
        v-for="(ind, index) in INDUSTRIES"
        :key="ind + '-list-' + index"
        :id="toPascalCase(ind)"
        :title="industryTitle.toUpperCase()"
        :title2="toTitleCase(ind)"
        :tagIds="[TAGS[industry], TAGS[ind]]"
        :inactiveSubLinks="inactiveSubLinks"
      ></ListOfProductsWithTag>
    </Main>
    <!-- <Main v-else>
      <h1>aksjdh</h1>
    </Main>-->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Hero from "@/molecules/Hero.vue";
import StackSubNav from "@/molecules/StackSubNav.vue";
import Lede from "@/molecules/Lede.vue";
import Intro from "@/components/Intro.vue";
import Main from "@/components/Main.vue";
import Footer from "@/components/Footer.vue";

import QuickLinks from "@/components/QuickLinks.vue";
import FilterSystem from "@/components/FilterSystem.vue";
import ListOfProductsWithTag from "@/components/ListOfProductsWithTag.vue";

import axios from "axios";

export default {
  name: "Stack",
  components: {
    Header,
    StackSubNav,
    Hero,
    Intro,
    Lede,
    Main,
    Footer,
    ListOfProductsWithTag,
    FilterSystem,
    QuickLinks
  },
  mounted: function() {
    axios
      .get(this.apiURL)
      .then(response => (this.ob = response.data[0]))
      .catch(error => console.log(error));

    // this.$scrollTo("#app", 100, {})
  },
  data: function() {
    return {
      ob: null,
      inactiveSubLinks: []
    };
  },
  computed: {
    apiURL: function() {
      return this.industry
        ? window.API + "/wp/v2/pages?slug=" + this.industry
        : window.API + "/wp/v2/pages";
    },
    industry: function() {
      return this.$route.params.industry;
    },
    industryTitle: function() {
      return this.toTitleCase(this.industry.replace("-", " "));
    },
    useProductFamilies: function() {
      return ![
        "office-software",
        "instruments",
        "machine-control",
        "data-collection"
      ].includes(this.industry);
    }
  },
  methods: {
    //
  }
};
</script>

<style scoped lang="scss">
.Stack {
  /deep/ .Lede {
    padding-top: 32px;
    padding-bottom: 0px !important;

    h1 {
      font-size: 28px;
      text-transform: none;
      color: $navy;
    }

    p {
      display: none;
    }

    /deep/ .Rule {
      padding-top: 12px;
    }
  }

  /deep/ .Intro {
    font-size: 16px !important;
    line-height: 32px !important;
  }
}
</style>
