<template>
  <nav class="Navigation">
    <ul class="primary">
      <NavLink
        v-for="(link, index) in links"
        :key="'navlink_' + index"
        :link="link"
      ></NavLink>
    </ul>
    <transition name="fade">
      <ul class="mobileMenu" v-if="showMobileMenu" @click="restartScrolling()">
        <Logo></Logo>
        <NavLink
          v-for="(link, index) in links"
          :key="'navlink_' + index"
          :link="link"
        ></NavLink>
        <br />
        <br />
        <form>
          <input
            v-model="mobileNavSearchTerm"
            type="text"
            placeholder="Search"
          />
          <button @click.prevent="goToSearchWithTerm">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </ul>
    </transition>
    <BurgerIcon :toggleFunction="toggleMobileMenu" />
    <transition name="fade">
      <MegaNav v-if="showMegaNav" />
    </transition>
  </nav>
</template>

<script>
import Logo from "@/atoms/Logo.vue";
import BurgerIcon from "@/atoms/BurgerIcon.vue";
import NavLink from "@/atoms/NavLink.vue";
import MegaNav from "@/components/MegaNav.vue";

export default {
  name: "Navigation",
  components: { Logo, NavLink, MegaNav, BurgerIcon },
  methods: {
    toggleMobileMenu: function() {
      this.showMobileMenu = !this.showMobileMenu;
      this.showMobileMenu && this.stopScrolling();
      !this.showMobileMenu && this.restartScrolling();
    },
    stopScrolling: function() {
      document.documentElement.classList.add("stopAllScrolling");
    },
    restartScrolling: function() {
      document.documentElement.classList.remove("stopAllScrolling");
    },
    goToSearchWithTerm: function() {
      this.$router.push({
        name: "Search",
        params: { searchTerm: this.mobileNavSearchTerm },
      });
    },
  },
  data: function() {
    return {
      showMobileMenu: false,
      showMegaNav: false,
      mobileNavSearchTerm: null,
      links: [
        {
          title: "Solutions",
          href: "/all-industries/",
          route: "About",
          active: false,
          megaNavShow: true,
        },
        {
          title: "Demo",
          href: "https://account.carlsonsw.com/demo",
          route: "About",
          active: false,
          megaNavShow: false,
        },
        {
          title: "Purchase",
          href: "/purchase/",
          route: "About",
          active: false,
          megaNavShow: false,
        },
        {
          title: "Support",
          href: "/support-and-training/",
          route: "About",
          active: false,
          megaNavShow: false,
        },
        {
          title: "About",
          href: "/about-us",
          route: "About",
          active: false,
          megaNavShow: false,
        },
        /*
        {
          title: "Classic Site",
          href: "https://classic.carlsonsw.com",
          route: "About",
          active: false,
          megaNavShow: false,
        },
*/
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.Navigation {
  text-align: right;
  padding-top: 32px;
  position: relative;

  @include smx {
    position: static;
  }

  @include med {
    text-align: center;
    width: 100%;
  }

  ul.primary {
    display: inline-block;
    text-align: left;
    @include smx {
      display: none;
    }
    @include med {
      text-align: center;
      width: 100%;
    }
  }

  ul.mobileMenu {
    padding-top: 16px;
    display: none;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: $navy;
    z-index: 1000;

    .Logo {
      margin-bottom: 48px;
    }

    li {
      display: block;
      font-size: 24px;
      padding: 16px 0;
      margin-left: 0;
    }
    @include smx {
      display: block;
    }
  }

  .mobileMenu form {
    font-family: inherit;
    display: inline-block;
    background: transparent !important;
    padding-left: 0 !important;

    input {
      font-family: inherit;
      font-size: 16px;
      padding: 5px 7px;
    }

    button {
      background: #979797;
      color: white;
      border: none;
      outline: none;
      padding: 2px 7px 3px;
      -webkit-appearance: none;

      i {
        font-size: 16px;
        padding: 7px 7px 8px;
      }
    }
  }
}
</style>
