import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Search from "./views/Search.vue";
import Stack from "./views/Stack.vue";
import ProductPage from "./views/ProductPage.vue";
import PageTemplate from "./views/PageTemplate.vue";
import PageTemplateWithWebPartial from "./views/PageTemplateWithWebPartial.vue";
import NewsTemplate from "./views/NewsTemplate.vue";
import NewsListTemplate from "./views/NewsListTemplate.vue";

Vue.use(Router);

const origin = window ? window.location.origin : "";

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 }; // This ensures that clicking a router link will go to top of page.
  },
  mode: "history",
  hash: false,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/home",
      redirect: "/",
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
    },
    {
      path: "/wp-admin",
      beforeEnter() {
        location.href = origin + "/api/wp-admin/index.php";
      },
    },
    {
      path: "/api/wp-admin",
      beforeEnter() {
        location.href = origin + "/api/wp-admin/index.php";
      },
    },
    {
      path: "/product/:productSlug",
      name: "Product",
      component: ProductPage,
    },
    {
      path: "/news",
      name: "News",
      component: NewsListTemplate,
    },
    {
      path: "/news/:newsSlug",
      name: "NewsPost",
      component: NewsTemplate,
    },
    {
      path: "/stack/:industry",
      name: "Stack",
      component: Stack,
    },
    // {
    //   path: "/updates",
    //   name: "UpdatesPagePartial",
    //   component: PageTemplateWithWebPartial,
    // },
    // {
    //   path: "/manuals",
    //   name: "ManualsPagePartial",
    //   component: PageTemplateWithWebPartial,
    // },
    // {
    //   path: "/knowledge-base",
    //   name: "KBPagePartial",
    //   component: PageTemplateWithWebPartial,
    // },
    // {
    //   path: "/reseller",
    //   name: "ResellerPagePartial",
    //   component: PageTemplateWithWebPartial,
    // },
    // {
    //   path: "/reseller-emea",
    //   name: "EMEAPagePartial",
    //   component: PageTemplateWithWebPartial,
    // },
    {
      path: "/:pageSlug",
      name: "Page",
      component: PageTemplate,
    },
    {
      path: "/:pageSlug/:childSlug",
      name: "PageChild",
      component: PageTemplate,
    },
    {
      path: "/:pageSlug/:middleSlug/:childSlug",
      name: "PageChildChild",
      component: PageTemplate,
    },
    {
      path: "/*",
      name: "PageBackup",
      component: PageTemplate,
    },
  ],
});
