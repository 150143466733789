import { render, staticRenderFns } from "./Lede.vue?vue&type=template&id=86ccf5aa&scoped=true&"
import script from "./Lede.vue?vue&type=script&lang=js&"
export * from "./Lede.vue?vue&type=script&lang=js&"
import style0 from "./Lede.vue?vue&type=style&index=0&id=86ccf5aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86ccf5aa",
  null
  
)

export default component.exports