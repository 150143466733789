<template>
  <div class="FilterSystem">
    <ul>
      <h4>All Tags</h4>
      <li
        @click="toggleActive(tag)"
        :class="{ active: activeTags.includes(tag) }"
        v-for="(tag, index) in allTags"
        :key="'tag' + index"
      >
        {{ tag.name }}
      </li>
    </ul>
    <Rule half />
    <ul>
      <h4>Relevant Products</h4>
      <li v-for="(product, index) in currentProducts" :key="'product-' + index">
        {{ product.title.rendered }} -
        <h6
          v-for="(label, index) in showTagLabels(product.tags)"
          :key="'tag-' + index"
        >
          {{ label }}&nbsp;-&nbsp;
        </h6>
      </li>
    </ul>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule.vue";

import axios from "axios";

export default {
  name: "FilterSystem",
  components: { Rule },
  props: {},
  data: function() {
    return {
      allTags: null,
      activeTags: [],
      currentProducts: [],
    };
  },
  mounted: function() {
    axios
      .get(window.API + "/wp/v2/tags?per_page=50")
      .then((response) => (this.allTags = response.data))
      .catch((error) => console.log(error));
  },
  methods: {
    showTagLabels: function(tagIds) {
      var set = tagIds.map((tagId) => {
        return this.getTagLabelFromId(tagId);
      });

      return set;
    },
    getTagLabelFromId: function(tagId) {
      return this.allTags
        .map((tag) => {
          return tagId == tag.id ? tag.name : null;
        })
        .filter((obj) => obj)[0];
    },
    toggleActive: function(tag) {
      if (this.activeTags.includes(tag)) {
        this.activeTags.splice(this.activeTags.indexOf(tag));
      } else {
        this.activeTags.push(tag);
      }

      this.refreshProducts();
    },
    refreshProducts: function() {
      var activeIds = this.activeTags.map((tag) => {
        return tag.id;
      });

      if (activeIds.length != 0) {
        axios
          .get(window.API + "/wp/v2/product?per_page=40&tags=" + activeIds)
          .then((response) => (this.currentProducts = response.data))
          .catch((error) => console.log(error));
      } else {
        this.currentProducts = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.FilterSystem {
  @extend .limit;
  @extend .destruct;

  h2 {
    color: $blue;
    padding-top: 32px;
    padding-bottom: 8px;
    max-width: 55%;
    @extend .destruct;
    font-size: 24px;
  }

  h4 {
    padding-bottom: 8px;
  }

  p {
    max-width: 55%;
    line-height: 32px;
    padding-bottom: 16px;
    @extend .destruct;
  }

  li {
    list-style: none;
    cursor: pointer;
  }

  .active {
    font-weight: bold;
  }

  h6 {
    display: inline-block;
    padding-bottom: 12px;
  }
}
</style>
