<template>
  <header class="Header">
    <Topbar />
    <div class="limit">
      <div class="top">
        <div class="left">
          <h1>
            <a href="/">
              <span class="hide">Carlson Software</span>
              <Logo></Logo>
            </a>
          </h1>
        </div>
        <div class="right">
          <Navigation></Navigation>
        </div>
      </div>
    </div>
    <div class="bottom">
      <slot></slot>
    </div>
  </header>
</template>

<script>
import Logo from "@/atoms/Logo.vue";
import Topbar from "@/molecules/Topbar.vue";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "Header",
  components: { Topbar, Logo, Navigation },
  data: function() {
    return {
      showLangOptions: false
    };
  }
};
</script>

<style scoped lang="scss">
.Header {
  background: $navy;
  color: $light;
  position: relative;

  .limit {
    width: 100% !important;

    .top {
      @extend .cf;

      .left {
        width: 30%;
        float: left;

        @include smx {
          width: 100%;
          float: none;
        }
        @include med {
          width: 100%;
          float: none;
        }
      }

      .right {
        width: 70%;
        float: right;

        @include med {
          width: 100%;
        }
      }
    }
  }

  .bottom {
    overflow: hidden;
    @include smx {
      // margin-bottom: 40px;
      overflow: visible;
    }
  }
}
</style>
