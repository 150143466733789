<template>
  <div class="Home">
    <Header>
      <Hero v-if="ob" :slides="ob.acf.hero_slides"></Hero>
    </Header>
    <Main>
      <QuickLinks></QuickLinks>
      <AdBlock v-if="acfOptions && (acfOptions.adblock_locations == 'home' || acfOptions.adblock_locations == 'both')"></AdBlock>
      <Lede v-if="ob" :title="ob.acf.intro.title">{{ ob.acf.intro.text }}</Lede>
      <div class="MegaNavContainer">
        <div class="pr">
          <MegaNav></MegaNav>
        </div>
      </div>
      <News :limit="3"></News>
    </Main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Hero from "@/molecules/Hero.vue";
import Main from "@/components/Main.vue";
import Footer from "@/components/Footer.vue";
import AdBlock from "@/components/AdBlock.vue";

import Lede from "@/molecules/Lede.vue";
import Circles from "@/components/Circles.vue";
import QuickLinks from "@/components/QuickLinks.vue";
import ProductNav from "@/components/ProductNav.vue";
import MegaNav from "@/components/MegaNav.vue";
import Testimonials from "@/components/Testimonials.vue";
import News from "@/components/News.vue";

import axios from "axios";

export default {
  name: "home",
  components: {
    Header,
    Hero,
    Main,
    AdBlock,
    Footer,
    Lede,
    Circles,
    QuickLinks,
    MegaNav,
    ProductNav,
    Testimonials,
    News,
  },
  mounted: function() {
    axios
      .get(window.API + "/wp/v2/pages?slug=home")
      .then((response) => (this.ob = response.data[0]))
      .catch((error) => console.log(error));
    
    axios.get(this.OPTIONS_ROUTE)
      .then((response) => {(this.acfOptions = response.data.acf)
      })
      .catch((error) => console.log(error));      
  },
  data: function() {
    return {
      ob: null,
      acfOptions: null
    };
  },
  methods: {
    fetchOb: function() {},
  },
};
</script>

<style lang="scss" scoped>
.Home {
  .MegaNavContainer {
    background: $midnight;
    .pr {
      position: relative;
      max-width: 825px;
      margin: 0 auto;

      /deep/ .MegaNav {
        position: static;
        border: none;
      }
    }
  }
}
</style>
