<template>
  <li class="NavLink" @mouseover="checkForMegaNavShow(link)">
    <a v-if="link.href.includes('http')" target="_blank" :href="link.href">{{
      link.title
    }}</a>
    <a
      v-else-if="link.href === '#' || link.href === '/#' || link.href === '/#/'"
      href="javascript:void(0)"
      >{{ link.title }}</a
    >
    <router-link v-else :class="{ active: link.active }" :to="link.href">{{
      link.title
    }}
      <i v-if="link.megaNavShow" class="fa fa-caret-down"></i>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "NavLink",
  props: {
    link: {
      type: Object,
    },
  },
  methods: {
    checkForMegaNavShow: function(link) {
      if (link.megaNavShow) {
        this.$parent.showMegaNav = true;
      } else {
        return false;
      }
    },
    hideMegaNav: function() {
      this.$parent.showMegaNav = false;
    },
  },
};
</script>

<style scoped lang="scss">
.NavLink {
  display: inline-block;
  list-style: none;
  margin-left: 24px;

  a {
    color: white;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.4s;

    &.active {
      color: $blue;
    }

    &.router-link-exact-active {
      color: $blue;
    }

    &:hover {
      color: $blue;
      transition: all 0.4s;
    }
  }
}
</style>
