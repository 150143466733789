<template>
  <div class="Main">
    <slot></slot>
  </div>
</template>

<script>
import Logo from "@/atoms/Logo.vue";

export default {
  name: "Main",
  components: { Logo },
};
</script>

<style scoped lang="scss">
.Main {
  max-width: 1680px;
  box-sizing: content-box;
  margin: 0 auto;
  background: white !important;
}
</style>
