<template>
  <nav
    class="MegaNav"
    @mouseover="$parent.showMegaNav = true"
    @mouseleave="$parent.showMegaNav = false"
  >
    <div class="spacer"></div>
    <ul class="industries">
      <h5>Industries</h5>
      <MegaNavLink
        v-for="(link, index) in industryLinks"
        :key="'navlink_' + index"
        :link="link"
      ></MegaNavLink>
    </ul>

    <Rule half center></Rule>

    <ul class="products">
      <h5>Solutions</h5>
      <MegaNavLink
        v-for="(link, index) in productLinks"
        :key="'navlink_' + index"
        :link="link"
      ></MegaNavLink>
    </ul>
  </nav>
</template>

<script>
import MegaNavLink from "@/atoms/MegaNavLink.vue";
import Rule from "@/atoms/Rule.vue";

export default {
  name: "MegaNav",
  components: { MegaNavLink, Rule },
  data: function() {
    return {
      industryLinks: [
        {
          title: "Land Development",
          href: "/stack/land-development",
          icon: "i_land.svg",
          active: false,
        },
        {
          title: "Mining",
          href: "/stack/mining",
          icon: "i_mining.svg",
          active: false,
        },
        // {
        //   title: "Waste Management",
        //   href: "/stack/waste-management",
        //   icon: "p_machine.svg",
        //   active: false,
        // },
        {
          title: "See All",
          href: "/all-industries",
          icon: "i_carlson.svg",
          active: false,
        },
      ],
      productLinks: [
        {
          title: "Office Software",
          href: "/stack/office-software",
          icon: "p_office.svg",
          active: false,
        },
        {
          title: "Data Collection",
          href: "/stack/data-collection",
          icon: "p_data_coll.svg",
          active: false,
        },
        {
          title: "Instruments",
          href: "/stack/instruments",
          icon: "p_instru.svg",
          active: false,
        },
        {
          title: "Machine Control",
          href: "/stack/machine-control",
          icon: "p_machine.svg",
          active: false,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.MegaNav {
  position: absolute;
  height: 400px;
  width: 100%;
  min-width: 700px;
  top: 68px;
  left: 0;
  z-index: 999999;
  background: $midnight;
  text-align: center;
  padding-top: 64px;
  @include med {
    text-align: center;
    width: 100%;
    display: none;
  }
  border-bottom: 2px solid #222;
  border-left: 2px solid #222;
  border-right: 2px solid #222;

  .spacer {
    background: transparent;
    position: absolute;
    height: 120px;
    width: 32%;
    top: -120px;
    z-index: 9999999;
    left: 0;
  }

  h5 {
    position: absolute;
    top: -40px;
    left: 24px;
    color: white;
  }

  .Rule {
    margin-top: 24px !important;
    margin-bottom: 64px !important;
  }

  ul {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 100%;
    @include smx {
      display: none;
    }
    @include med {
      text-align: center;
      width: 100%;
    }
  }
}
</style>
