<template>
  <div v-if="items.length" class="PageSubNav" :class="{ fix: currentScroll > 100 }">
    <ul>
      <li
        @click="toggleMobileSubNav()"
        :class="`subNavTitle ${showMobileSubNav ? 'mobileShowing' : ''}`"
      >
        <a href="/" v-if="currentScroll > 100"><i class="fa fa-home" aria-hidden="true"> &nbsp;</i></a>
        {{ title }}
      </li>
      <transition name="fade">
        <li class="returnArrow" v-scroll-to="'#app'" v-if="currentScroll > 100">
          Top <i class="fa">&#xf139;</i>
        </li>
      </transition>
      <template v-for="(item, index) in items">
        <li
          :class="{ mobileShowing: showMobileSubNav }"
          :key="'label-' + index"
          v-scroll-to="item.anchor_link"
        >
          {{ item.nav_label }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PageSubNav",
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    demoLinkOverride: {
      type: String,
    },
  },
  data() {
    return {
      fixed: false,
      currentScroll: 0,
      showMobileSubNav: false,
    };
  },
  methods: {
    handleScroll: function() {
      this.currentScroll = window.scrollY;
    },
    toggleMobileSubNav: function() {
      this.showMobileSubNav = !this.showMobileSubNav;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.PageSubNav {
  background: $blue;
  box-shadow: 0px 1px 4px #444;
  position: relative;
  z-index: 999;

  &.fix {
    @include smp {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  ul {
    @extend .limit;
    text-align: right;
    background: $blue;
    width: 100%;
    @include smx {
      position: absolute;
      top: 0;
      left: 0;
    }

    li {
      cursor: pointer;
      list-style: none;
      display: inline-block;
      padding-right: 22px;
      padding-bottom: 8px;

      @include smx {
        text-align: left;
        padding-left: 24px;
        padding-bottom: 24px;
        font-size: 12px !important;
        display: none;
        &.mobileShowing {
          display: block;
        }
      }

      &.subNavTitle {
        cursor: default;
        float: left;
        font-weight: bold;
        @include smx {
          float: none;
          padding-left: 0;
          display: block;
          padding-bottom: 8px;
          &.mobileShowing {
            padding-bottom: 24px;
          }
          &:after {
            content: "";
            display: inline-block;
            @include arrow-right(4px, white);
            position: relative;
            top: -1px;
            left: 6px;
          }
        }
      }

      &:last-of-type {
        padding-right: 0;
      }

      a {
        text-decoration: none;
        color: white !important;
      }

      &.returnArrow {
        color: white;
        display: inline-block;
        padding: 4px 0px 8px 18px;
        padding-right: 18px;

        @include smx {
          display: none;
        }

        span {
          display: inline-block;
          border: 1px solid white;
          border-radius: 1000px;
          width: 21px;
          height: 21px;
          padding-top: 1px;
          font-size: 12px;
          vertical-align: top;
          text-align: center;
        }
      }
    }
  }
}
</style>
