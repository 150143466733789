<template>
  <div class="Footer">
    <div class="top">
      <!-- <img v-if="!noImage" src="@/assets/img/footer.png"/> -->
      <img src="@/assets/img/footer.jpg" />
    </div>

    <div class="mid">
      <h4>Carlson Software</h4>
      <Rule :color="COLORS.$light"></Rule>
      <section>
        <h5>Products</h5>
        <ul>
          <li>
            <a href="/stack/land-development">Land Development</a>
          </li>
          <li>
            <a href="/stack/mining">Mining</a>
          </li>
          <li>
            <a href="/all-industries">All Industries</a>
          </li>
        </ul>
      </section>

      <section>
        <h5>Support</h5>
        <ul>
          <li>
            <a href="/support-and-training#support">Find Help</a>
          </li>
          <li>
            <a href="https://update.carlsonsw.com/redirect/?kb">Knowledgebase</a>
          </li>
          <li>
            <a href="https://web.carlsonsw.com/academy">Academy</a>
          </li>
        </ul>
      </section>

      <section>
        <h5>Purchase</h5>
        <ul>
          <li>
            <a href="https://account.carlsonsw.com/contact">Contact Sales</a>
          </li>
          <li>
            <a href="/purchase#dealer">Dealer</a>
          </li>
          <li>
            <a href="https://account.carlsonsw.com/purchase">Buy Online</a>
          </li>
        </ul>
      </section>

      <section>
        <h5>About</h5>
        <ul>
          <li>
            <a href="/about-us#mission">Who We Are</a>
          </li>
          <li>
            <a href="/news">News</a>
          </li>
          <li>
            <a href="/about-us#careers">Careers</a>
          </li>
        </ul>
      </section>
    </div>

    <div class="bottom">
      <div class="limit">
        <div class="left">
          <span class="copy">&copy;2024 Carlson Software</span>
          <ul class="fine">
            <li>
              <a href="/about-us/privacy-policy">Privacy Policy</a>
            </li>
            <!--
            <li>
              <a href="/about-us/sitemap">Sitemap</a>
            </li>
            -->
          </ul>
        </div>
        <div class="center">
          <ul class="social">
            <li>
              <a href="https://www.facebook.com/carlsonsw">
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/carlson-software">
                <i class="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/CarlsonSoftware">
                <i class="fab fa-twitter inv"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/CarlsonSoftware">
                <i class="fab fa-youtube inv"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--
        <div class="right">
          <TextInput placeholder="Sign Up for Email Updates!"></TextInput>
          <Button :quarter="true" text="Submit"></Button>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule.vue";
import Button from "@/atoms/Button.vue";
import TextInput from "@/atoms/TextInput.vue";

export default {
  name: "Footer",
  components: { Rule, Button, TextInput },
  props: {
    noImage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.Footer {
  font-size: 14px !important;
  background: $navy;
  @include med {
    .hasQL & {
      padding-bottom: 88px;
    }
  }

  .top {
    border-top: 4px solid $navy;
    img {
      width: 100%;
      display: block;
      max-height: 220px;
      object-fit: cover;
    }
  }
  .mid {
    @extend .cf;
    @extend .limit;
    @extend .destruct;
    padding-top: 24px;

    h4 {
      padding-top: 16px;
      color: white;
      font-size: 20px;
      text-transform: none;
    }

    .Rule {
      margin-bottom: 32px;
    }

    section {
      width: 25%;
      float: left;
      padding-bottom: 24px;
      @include med {
        width: 50% !important;
      }
      @include med {
        width: 50%;
      }

      h5 {
        color: white;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.06125em;
        margin-bottom: 16px;
      }

      ul {
        padding-bottom: 16px;

        li {
          margin-left: 20px;
          list-style-type: square;
          color: $blue;
          padding-bottom: 8px;

          a {
            color: white;
            @include funLink($light);
            @include med {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .bottom {
    @extend .cf;
    @extend .destruct;
    background: #555;
    padding-bottom: 48px !important;
    border-top: 1px solid #999;

    .limit {
      padding-top: 24px;
      @extend .destruct;
      @include med {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .left {
      float: left;
      width: 35%;
      color: white;
      padding-top: 16px;
      @extend .destruct;

      ul {
        li {
          display: inline-block;
          padding-right: 10px;
          @extend .destruct;
          @include med {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
          &:not(:last-of-type):after {
            @include med {
              display: none;
            }
            content: "|";
            position: relative;
            top: 2px;
            display: inline-block;
            padding-left: 10px;
          }
          a {
            text-decoration: none;
            @include med {
              font-size: 12px;
            }

            @include funLink($light);
          }
        }
      }
    }

    .center {
      width: 27%;
      float: left;
      padding-top: 24px;
      text-align: center;
      @extend .destruct;

      li {
        display: inline-block;
        width: 55px;
        a {
          color: white;
          transition: all 0.4s;

          &:hover {
            opacity: 0.5;
            transition: all 0.4s;
          }

          i {
            vertical-align: middle;

            &.fa-facebook {
              font-size: 42px;
            }

            &.fa-linkedin {
              font-size: 42.5px;
            }

            &.inv {
              background: white;
              color: #555;
              padding: 5px;
              font-size: 28px;
              border-radius: 2px;
            }
          }
        }
      }
    }

    .right {
      width: 38%;
      float: right;
      text-align: right;
      @extend .destruct;

      .TextInput {
        max-width: 310px;
        width: 72%;
        @include med {
          width: 100%;
          display: block;
          max-width: none;
        }
      }

      .Button {
        // padding: 12px 16px;
        // width: 28% !important;
        @include med {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
