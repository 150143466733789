<template>
  <div
    v-if="src"
    class="YoutubeVideo"
    :class="{ half: half, thirds: thirds, third: third }"
  >
    <div class="videoWrap">
      <iframe
        width="720"
        height="694"
        :src="src"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule";

export default {
  name: "YoutubeVideo",
  components: { Rule },
  props: {
    half: {
      type: Boolean,
    },
    thirds: {
      type: Boolean,
    },
    third: {
      type: Boolean,
    },
    src: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.YoutubeVideo {
  &.half {
    width: 50%;
    @extend .limit;
    @extend .destruct;
  }

  &.thirds {
    width: calc(66.6666% - 48px);
    @extend .limit;
    @extend .destruct;
    float: left;
    margin-left: 48px;
  }

  &.third {
    width: calc(33.3333% - 48px);
    @extend .limit;
    @extend .destruct;
    float: left;
    margin-left: 48px;
  }

  .spacer {
    padding-bottom: 127px;
    width: 100%;
    height: 1px;
  }

  .videoWrap {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
