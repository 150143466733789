<template>
  <div class="StackSubNav" :class="{ fix: currentScroll > 100 }">
    <ul>
      <li>
        <a href="/" v-if="currentScroll > 100"><i class="fa fa-home" aria-hidden="true"> &nbsp;</i></a>
        {{ title }}
      </li>
      <transition name="fade">
        <li class="returnArrow" v-scroll-to="'#app'" v-if="currentScroll > 100">
          Top <i class="fa">&#xf139;</i>
        </li>
      </transition>
      <li
        v-for="(link, index) in filteredLinks"
        :key="'link-' + index"
        v-scroll-to="'#' + toPascalCase(link)"
      >
        {{ toTitleCase(link) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StackSubNav",
  props: {
    links: {
      type: Array,
    },
    title: {
      type: String,
    },
    demoLinkOverride: {
      type: String,
    },
    tag: {
      type: String,
    },
    inactiveSubLinks: {
      type: Array,
    },
  },
  data() {
    return {
      fixed: false,
      currentScroll: 0,
    };
  },
  methods: {
    handleScroll: function() {
      this.currentScroll = window.scrollY;
    },
  },
  computed: {
    filteredLinks: function() {
      return this.links.filter((ln) => {
        if (this.inactiveSubLinks.length) {
          for (var i = 0; i < this.inactiveSubLinks.length; i++) {
            return !this.inactiveSubLinks[i].includes(this.toTitleCase(ln));
          }
        } else {
          return true;
        }
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.StackSubNav {
  background: $blue;
  box-shadow: 0px 1px 4px #444;
  position: relative;
  z-index: 9999;

  &.fix {
    @include smp {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  ul {
    @extend .limit;
    text-align: right;

    @include smx {
      text-align: center;
    }

    li {
      cursor: pointer;
      list-style: none;
      display: inline-block;
      padding-right: 16px;
      padding-bottom: 8px;

      &:first-of-type {
        cursor: default;
        float: left;
        font-weight: bold;
        @include smx {
          display: none;
        }
      }

      &:last-of-type {
        padding-right: 0;
      }

      a {
        text-decoration: none;
        color: white !important;
      }

      &.returnArrow {
        color: white;
        display: inline-block;
        padding: 4px 0px 8px 18px;
        padding-right: 18px;

        @include smx {
          display: block;
        }

        span {
          display: inline-block;
          border: 1px solid white;
          border-radius: 1000px;
          width: 21px;
          height: 21px;
          padding-top: 1px;
          font-size: 12px;
          vertical-align: top;
          text-align: center;
        }
      }
    }
  }
}
</style>
