<template>
  <div class="ListOfProductsWithTag" v-if="currentProducts.length !== 0">
    <slot></slot>
    <ul>
      <h3>{{ title }} &nbsp;<span class="bar">|</span>&nbsp; <span class="title2">{{ title2 }}</span></h3>
      <p v-if="computedBlurb" class="stackBlurb">{{computedBlurb}}</p>
      <ProductPreviewGridItem
        v-for="(product, index) in currentProducts"
        :key="'product-' + index"
        :product="product"
      ></ProductPreviewGridItem>
    </ul>
  </div>
</template>

<script>
import Rule from "@/atoms/Rule.vue";
import ProductPreviewGridItem from "@/components/ProductPreviewGridItem.vue";
import axios from "axios";
import { STACK_BLURBS } from "@/content.js";

export default {
  name: "ListOfProductsWithTag",
  components: { Rule, ProductPreviewGridItem },
  props: {
    title: {
      type: String,
    },
    title2: {
      type: String,
    },
    tagIds: {
      type: Array,
    },
    shouldOr: {
      type: Boolean,
      default: false,
    },
    inactiveSubLinks: {
      type: Array,
    },
  },
  data: function() {
    return {
      currentProducts: [], 
      blurbs: {}     
    };
  },
  mounted: function() {
    axios
      .get(window.API + "/wp/v2/tt/" + this.parsedTagIds)
      .then((response) => {
// John E: Exclude Carlson Command from WASTE MANAGEMENT - Machine Control
// as per Anthony Chamblin 20220225
        if(this.parsedTagIds == '16+12') {
          for(let i = 0; i < response.data.length; i++)
            if(response.data[i].ID != '109')
              this.currentProducts.push(response.data[i]);
        }
        else
          this.currentProducts = response.data;
      })
      .then(() => {
        if (this.currentProducts.length === 0) {
          this.inactiveSubLinks.push(this.title);
        }
      })
      .catch((error) => console.log(error));

    this.blurbs = STACK_BLURBS
  },
  computed: {
    parsedTagIds: function() {
      var value = "";
      var len = this.tagIds.length - 1;
      if (!this.shouldOr) {
        this.tagIds.forEach((id, index) => {
          value += id;
          value += index !== len ? "+" : "";
        });
      }
      return value;
    },
    computedBlurb: function() {
      let key1 = this.title?.replace(" ", "_").toUpperCase()
      let key2 = this.title2?.replace(" ", "_").toUpperCase()
            
      if (key1 && key2 && this.blurbs[key1] != null && this.blurbs[key1][key2] != null) {
        return this.blurbs[key1][key2]
      } else if (key1 && key2 && this.blurbs[key2] != null && this.blurbs[key2][key1] != null) {
        return this.blurbs[key2][key1]
      }
      else {
        return null
      }
    }
  },
};
</script>

<style scoped lang="scss">
.ListOfProductsWithTag {
  @extend .limit;
  @extend .destruct;
  overflow: hidden;

  .title2 {
    color: $blue;
  }

  .bar {
    font-weight: 300;
    color: #ddd
  }

  .stackBlurb {
    color: #444;
    max-width: 65%;
    line-height: 1.75;
    margin-bottom: 24px;
  }

  h3 {
    color: $navy;
    padding-top: 20px;
    padding-bottom: 24px;
    max-width: 55%;
    @extend .destruct;
    font-size: 22px;
    text-transform: none !important;

    @include med {
      padding-left: 0 !important;
    }
  }
}
</style>
